import { en } from 'vuetify/lib/locale/index.mjs';

import countries from '@/i18n/lang/countries/en';
import currencies from '@/i18n/lang/currencies/en';
import saudiCities from '@/i18n/lang/saudi_cities/en';

export default {
  $vuetify: { ...en },
  ...countries,
  ...currencies,
  ...saudiCities,
  I_HAVE_ACCOUNT_LOGIN: 'I have an account, Login',
  CONFIRM_REGISTRATION: 'Confirm Registration',
  'CRITERIA_TYPES.dropdown-choices': 'dropdown-choices',
  'CRITERIA_TYPES.multiple-choices': 'multiple-choices',
  'CRITERIA_TYPES.questions': 'questions',
  'CRITERIA_TYPES.single-choice': 'single-choice',
  'ERROR.AN_ERROR_OCCURED_PLEASE_TRY_AGAIN_LATER': 'An error occured, please try again later!',
  'ERROR.FAILED_TO_LOAD_DATA': 'Failed to load data!',
  'ERROR.FILE_WAS_REMOVED_SUCCESSFULLY': 'File was removed successfully',
  'ERROR.FILE_WAS_UPLOADED_SUCCESSFULLY': 'File was uploaded successfully',
  'ERROR.NO_IMAGES_TO_BE_UPLOADED': 'No Images to be Uploaded',
  'PAGINATE.10': '10',
  'PAGINATE.100': '100',
  'PAGINATE.20': '20',
  'PAGINATE.30': '30',
  'PAGINATE.5': '5',
  'PAGINATE.50': '50',
  'BOOKING_#': 'Booking #',
  DATE: 'Date',
  'PAGINATE.ALL': 'All',
  'STATUS_STRINGS.ACCEPTED': 'Accepted',
  'STATUS_STRINGS.ACTIVE': 'Active',
  NEW_PACKAGE: 'New Package',
  'STATUS_STRINGS.ALL': 'All',
  'STATUS_STRINGS.BLOCKED': 'Blocked',
  ACCEPT_TERMS_AND_CONDITIONS: 'Accept Terms & Conditions',
  TERMS_AND_CONDITIONS: 'Terms & Conditions',
  'STATUS_STRINGS.CANCELED': 'Canceled',
  'STATUS_STRINGS.DELETED': 'Deleted',
  'STATUS_STRINGS.DRAFT': 'draft',
  'STATUS_STRINGS.EXPIRED': 'expired',
  'STATUS_STRINGS.HIDDEN': 'Hidden',
  'STATUS_STRINGS.INACTIVE': 'Inactive',
  'STATUS_STRINGS.INITIALIZED': 'Pending',
  'STATUS_STRINGS.PAID': 'Paid',
  'STATUS_STRINGS.PENDING': 'Pending',
  'STATUS_STRINGS.REJECTED': 'Rejected',
  'STATUS_STRINGS.REQUESTED': 'Requested',
  'STATUS_STRINGS.SOLVED': 'Solved',
  'STATUS_STRINGS.SHOWN': 'Shown',
  'STATUS_STRINGS.UNKNOWN': 'Unknown',
  'STATUS_STRINGS.UNPAID': 'Unpaid',
  ACCEPTED_PAYMENTS: 'Accepted Payments',
  ACCOUNT_TYPE: 'Account Type',
  PAYMENT_GATEWAY_RESPONSE: 'Payment Gateway Response',
  ADD_CHOICE: 'Add Choice',
  CLIENT_DETAILS: 'Client Details',
  DETAILS: 'Details',
  TRANSFER: 'Bank Transfer',
  CASH: 'Cash',
  ADD_QUESTION: 'Add Question',
  ADDRESS: 'Address',
  CREATED_SUCCESSFULLY: 'Created Successfully',
  UPDATED_SUCCESSFULLY: 'Updated Successfully',
  NO_SERVICE_PACKAGES_FOUND: 'No Service Packages Found',
  CLICK_HERE_TO_ADD_NEW_PACKAGE: 'Click Here To Add New Package',
  ADMIN: 'Admin',
  ADS_TITLE: 'Ads Title',
  ADVERTISEMENT_LIST: 'Advertisement List',
  ADVERTISEMENT_TYPE_IDENTIFIER: 'Type Identifier',
  ADVERTISEMENT_TYPE_LIST: 'Advertisement Type List',
  ADVERTISEMENT_TYPE_TITLE: 'Advertisement Type',
  ADVERTISEMENT_TYPE: 'Advertisement Type',
  ADVERTISEMENT_TYPES: 'Advertisement Types',
  ADVERTISEMENTS: 'Advertisements',
  AMOUNT: 'Amount',
  ANSWER_TYPE: 'Answer Type',
  AUTO_APPLY: 'Auto Apply',
  AUTO: 'Auto Apply',
  LICENSE_TYPE: 'License Type',
  LICENSE_TYPE_INDEX_TITLE: 'License Type',
  FREELANCE_LICENSE: 'Freelance License',
  BACK: 'Back',
  BIRTHDATE: 'Birthdate',
  BOOK_BEFORE: 'Booking Before ...',
  BOOK_BEFORE_DAYS: 'Day(s)',
  BOOKING_COUNTER: 'Booking Counter',
  BOOKING_DATE: 'Booking Date',
  BOOKING_LIST: 'Booking List',
  BOOKING_NUMBER: 'Booking Number',
  BOOKING_PRICE: 'Booking Price',
  BOOKINGS: 'Bookings',
  BUSINESS_INFORMATION: 'Business Information',
  BUSINESS_NAME: 'Business Name',
  CANCEL: 'Cancel',
  CAPACITY: 'Capacity',
  CHANGE_IMAGE: 'Change',
  CHOICE_LIST: 'Choice List',
  CHOICE: 'Choice',
  CHOICES: 'Choices',
  CHOOSE_ACCOUNT_TYPE_DESCRIPTION: 'Access a customized control panel designed specifically for your needs by selecting your account type',
  CHOOSE_ACCOUNT_TYPE: 'Choose Your Account Type',
  CITIES_LIST: 'City List',
  CITIES: 'Cities',
  CITY_LIST: 'City List',
  CLEAR_SEARCH: 'Clear Search',
  CLEAR: 'Clear',
  CLIENT_GROUP: 'Client Operations',
  CLIENT_MESSAGE: 'Organize and manage your own events with us',
  CLIENT_NAME: 'Client Name',
  CLIENT_PHONE: 'Client Phone',
  CLIENT: 'Client',
  CLOSE: 'Close',
  CODE: 'Code',
  COLOR: 'Color',
  COMMENT_STATUS: 'Comment Status',
  COMMENT: 'Comment',
  COMMENTED_AT: 'Commented at',
  COMMERCIAL_REGISTER: 'Commercial Register',
  LICENSE_NUMBER: 'License Number',
  CONFIRM: 'Confirm',
  FEES: 'Fees',
  COUNTRIES: 'Countries',
  COUNTRY_LIST: 'Country List',
  COUNTRY_NAME: 'Country Name',
  COUNTRY: 'Country',
  COVER_IMAGE: 'Cover Image',
  PAYMENT_DETAILS: 'Payment Details',
  BOOKING_DETAILS: 'Booking Details',
  PAYMENT_GATEWAY_DETAILS: 'Payment Gateway Details',
  CREATED_AT: 'Created At',
  CRITERIA_LIST: 'Criteria List',
  CRITERIA_TYPE: 'Criteria Type',
  CRITERIAS: 'Criterias',
  CURRENCY_CODE: 'Currency Code',
  CURRENCY_NAME: 'Currency Name',
  DASHBOARD_GROUP: 'Dashboard',
  DASHBOARD: 'Dashboard',
  DAY: 'Day',
  DELETE_ADVERTISEMENT_TYPE: 'Delete Advertisement Type',
  DELETE_ADVERTISEMENT: 'Delete Advertisement',
  DELETE_BOOKING: 'Delete Booking',
  DELETE_CITY: 'Delete City',
  DELETE_COUNTRY: 'Delete Country',
  DELETE_CRITERIA: 'Delete Criteria',
  DELETE_DISCOUNT_CODE: 'Delete Discount Code',
  DELETE_EVENT_TYPE: 'Delete Event Type',
  DELETE_PAYMENT: 'Delete Payment',
  DELETE_SERVICE_PACKAGE: 'Delete Service Package',
  DELETE_SERVICE_TYPE: 'Delete Service Type',
  DELETE_SERVICE: 'Delete Service',
  DELETE_SUPPORT_MESSAGE: 'Delete Support Message',
  DELETE_USER_EVENT: 'Delete User Event',
  DELETE_USER: 'Delete User',
  DELETE: 'Delete',
  DELETION_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this record? This process is irreversible!',
  DESCRIPTION: 'Description',
  DISCOUNT_CODE_LIST: 'Discount Code List',
  DISCOUNT_CODE_STRING: 'Discount Code',
  DISCOUNT_CODE_USE_COUNTER: 'The times the discount code was used is {counter} out of {quantity}',
  DISCOUNT_CODES: 'Discount Codes',
  DISCOUNT_PERCENTAGE: 'Discount Percentage',
  DISPLAY_KEY: 'Display Key',
  DISPLAY_NAME: 'Display Name',
  DRAFT_PAYMENTS: 'Draft Payments',
  DROPDOWN_CHOICES: 'Dropdown Choices',
  EDIT: 'Edit',
  EMAIL: 'Email',
  END_DATE: 'End Date',
  EVENT_TITLE: 'Event Title',
  EVENT_TYPE_LIST: 'Event Type List',
  EVENT_TYPE: 'Event Type',
  EVENT_TYPES: 'Event Types',
  EXPIRED_PAYMENTS: 'EXPIRED Payments',
  FILTER: 'Filter',
  FULL_NAME: 'Full Name',
  GO_BACK: 'Go Back',
  GO_TO_HOME: 'Go To Home',
  HIDDEN: 'Hidden',
  HOME: 'Home',
  HOUR: 'Hour',
  ICON: 'Icon',
  ISO_CODE_THREE: 'Iso Code 3',
  ISO_CODE_TWO: 'Iso Code 2',
  ISO_CODE: 'Iso Code',
  ISSUE: 'Issue',
  LATITUDE: 'Latitude',
  LETS_EVENT_VERSION: 'Version',
  LETS_EVENT: 'Lets Event',
  LOGIN: 'Login',
  LOGO_WAS_REMOVED_SUCCESSFULLY: 'Logo Was Removed Successfully',
  LOGO_WAS_UPLOADED_SUCCESSFULLY: 'Logo Was Uploaded Successfully',
  LONGITUDE: 'Longitude',
  MESSAGE_TYPE: 'Message Type',
  MESSAGE: 'Message',
  MONTH: 'Month',
  MULTI_LINE: 'Multi Line',
  MULTIPLE_CHOICES: 'Multiple Choices',
  MY_PROFILE: 'My Profile',
  NATIONAL_ID: 'National Id',
  NATIONALITY: 'Nationality',
  NEW_ACCOUNT: 'New Account',
  NEW_RECORD: 'New Record',
  NEW: 'NEW',
  'USER_TYPE.CLIENT': 'Client',
  'USER_TYPE.MERCHANT': 'Merchant',
  'USER_TYPE.SUPERADMIN': 'Superadmin',
  PERSONAL_INFORMATION: 'Personal Information',
  ACCEPTED: 'Accepted',
  CONTACT_INFORMATION: 'Contact Information',
  ADDRESS_INFORMATION: 'Address Information',
  ADDRESS_DESCRIPTION: 'Address Description',
  OWNER_NAME: 'Owner Name',
  BOOKING_INFORMATION: 'Booking Information',
  COMMENT_DETAILS: 'Comment Details',
  BUSINESS_LOGO: 'Business Logo',
  FAVORITE: 'Favorite',
  LOCATION: 'Location',
  PACKAGE_CAPACITY: 'Package Capacity',
  PACKAGE_TITLE: 'Package Title',
  PAID: 'Paid',
  MERCHANT_DETAILS: 'Merchant Details',
  REQUESTED: 'Requested',
  NEXT_STEP: 'NEXT STEP',
  NO_IMAGES_TO_BE_REMOVED: 'No Images To Be Removed',
  NO_SERVICE_PACKAGES_FOUND_CLICK_ADD: 'Note: This service will not be displayed due to the absence of available packages. Please add a new package.',
  NOT_AUTO: 'Not Auto Apply',
  NUM_OF_PEOPLE: '{n} person | {n} people',
  NUMBER: 'Number',
  OTP_DESCRIPTION: 'Please enter the OTP code sent to your mobile number',
  OTP_TITLE: 'Authentication Code',
  PACKAGE_LIST: 'Package List',
  PACKAGE_NAME: 'Package Name',
  PAGE_NOT_EXIST: 'Page Not Exist',
  PAGE_NOT_FOUND: 'Page Not Found',
  PASSWORD_CONFIRMATION: 'Password Confirmation',
  PASSWORD: 'Password',
  PAYMENT_GATEWAY: 'Payment Gateway',
  PAYMENT_INVOICES: 'Payment Invoices',
  PAYMENT_LIST: 'Payment List',
  PAYMENT_NUMBER: 'Payment Number',
  PAYMENT_URL: 'Payment URL',
  PAYMENTS: 'Payments',
  PENDING_PAYMENTS: 'Pending Payments',
  PENDING: 'Pending',
  PERCENTAGE: 'Percentage',
  PERSONAL_NAME: 'Personal Name',
  PHONE_CODE: 'Phone Code',
  PHONE: 'Phone',
  PLEASE_ENTER_YOUR_EMAIL_AND_PASSWORD: 'Please Enter Your Email & Password',
  PLEASE_ENTER_YOUR_PHONE_NUMBER: 'Please Enter Your Phone Number',
  PRICE_PER_DAY: 'Price Per Day',
  PRICE_RANGE: 'Price Range',
  PRICE: 'Price',
  PRINT: 'Print',
  PROFILE_GROUP: 'Profile',
  MERCHANT_GROUP: 'Merchant Operations',
  MERCHANT_NAME: 'Merchant Name',
  MERCHANT: 'Merchant',
  QUANTITY: 'Quantity',
  QUESTION_LIST: 'Question List',
  QUESTION: 'Question',
  QUESTIONS: 'Questions',
  RATE_BAD: '1-Bad',
  RATE_EXCELLENT: '5-Excellent',
  RATE_GOOD: '4-Good',
  RATE_NEUTRAL: '3-Neutral',
  RATE_NOT_GOOD: '2-Not Good',
  RATE: 'Rate',
  RATINGS: '{n} rating | {n} ratings',
  REFRESH: 'Refresh',
  REGISTER: 'Register',
  REGISTRATION_FORM_DESCRIPTION: "This form is exclusively for Merchants. However, clients can log in using their mobile number, even if they haven't created an account previously.",
  REJECTED_PAYMENTS: 'Rejected Payments',
  REMOVE_SELECTED_IMAGES: 'Remove Selected Images',
  REMOVE: 'Remove',
  RESPONSE: 'Response',
  SEARCH: 'Search',
  SENDER: 'Sender',
  SENT_AT: 'Sent At',
  SERVICE_IMAGE: 'Service Image',
  SERVICE_IMAGES: 'Service Images',
  SERVICE_LIST: 'Service List',
  SERVICE_NAME: 'Service Name',
  SERVICE_PACKAGE_LIST: 'Service Package List',
  SERVICE_PACKAGE: 'Service Package',
  SERVICE_PACKAGES: 'Service Packages',
  MERCHANT_MESSAGE: 'Offer your services and products with us',
  MERCHANT_REGISTRATION_FORM: 'Merchant Registration Form',
  SERVICE_TYPE_LIST: 'Service Type List',
  SERVICE_TYPE: 'Service Type',
  SERVICE_TYPES: 'Service Types',
  SERVICE: 'Service',
  SERVICES: 'Services',
  SETTINGS_GROUP: 'General Settings',
  SHOWN: 'Shown',
  SIGN_OUT: 'Sign Out',
  SINGLE_CHOICE: 'Single Choice',
  SINGLE_LINE: 'Single Line',
  START_DATE: 'Start Date',
  STATUS: 'Status',
  SUBMIT: 'Submit',
  SUBTITLE: 'Subtitle',
  SUPERADMIN: 'Superadmin',
  SUPPORT_MESSAGE_LIST: 'Support Message List',
  SUPPORT_MESSAGES_GROUP: 'Support Messages',
  SUPPORT_MESSAGES: 'Support Messages',
  TITLE: 'Title',
  TRANSACTION_NUMBER: 'Transaction Number',
  TYPE_IDENTIFIER: 'Type Identifier',
  TYPE: 'Type',
  UNDEFINED: 'Undefined',
  UPDATED_AT: 'Updated At',
  UPLOAD_IMAGE: 'Upload Image',
  UPLOAD_IMAGES: 'Upload New Images',
  UPLOAD: 'Upload',
  USER_CLIENTS: 'Client List',
  USER_EVENT_LIST: 'User Event List',
  USER_EVENT: 'User Event',
  USER_EVENTS: 'User Events',
  USER_LIST: 'User List',
  USER_MERCHANTS: 'Merchants',
  USER_SUPERADMINS: 'Superadmin List',
  USER_TYPE: 'User Type',
  USERS_GROUP: 'User List',
  USERS: 'Users',
  VIEW_SERVICE_IMAGES: '{n} Service Image | {n} Service Images',
  WEEK: 'Week',
  YOU_CAN_NOT_REMOVE_THIS_RECORD: 'You can not remove this record',
};
