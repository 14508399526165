// TODO: refactor this file
import { defineStore } from 'pinia';
import { parse, stringify } from 'zipson';

import env from '@/env';
import { type SupportedLanguage, FALLBACK_LANGUAGE } from '@/i18n';
import { iUser } from '@/interfaces/interfaces';
import logoImage from '@/assets/logo.png';

type ConfigState = {
  _themeDark: boolean;
  _locale: string;
  _langDirection: 'ltr' | 'rtl';
  _mode: 'view' | 'create' | 'edit' | undefined;
};

export default defineStore('config', {
  state: (): ConfigState => ({
    _themeDark: window.matchMedia('(prefers-color-scheme: dark)').matches,
    _locale: localStorage.getItem('locale') || FALLBACK_LANGUAGE,
    _langDirection: 'rtl',
    _mode: undefined,
  }),
  getters: {
    themeDark(state): boolean {
      return state._themeDark;
    },
    locale(state): string {
      return state._locale;
    },
    langDirection(state): string {
      return state._langDirection;
    },
    mode(state): string | undefined {
      return state._mode;
    },
  },
  actions: {
    toggleLightDarkMode() {
      this._themeDark = !this._themeDark;
    },
    setLocale(locale: SupportedLanguage) {
      this._locale = locale;
      localStorage.setItem('locale', locale);
      location.reload();
    },
    setLangDirection(langDirection: 'ltr' | 'rtl') {
      this._langDirection = langDirection;
    },
    setMode(mode: 'view' | 'create' | 'edit' | undefined) {
      this._mode = mode;
    },
    stringLang(arabicString: string | null, englishString: string | null) {
      return this._locale === 'en' || arabicString == null
        ? (englishString as string)
        : arabicString;
    },
    superadminEditMode() {
      return (
        (this._mode == 'edit' || this._mode == 'create') &&
        localStorage.getItem('userType') == 'superadmin'
      );
    },
    merchantEditMode(superadmin?: boolean) {
      const editOrCreate = this._mode === 'edit' || this._mode === 'create';
      const userType = localStorage.getItem('userType') ?? '';
      if (superadmin) {
        return editOrCreate && ['superadmin', 'merchant'].includes(userType);
      } else {
        return editOrCreate && userType == 'merchant';
      }
    },
    clientEditMode(superadmin?: boolean) {
      const editOrCreate = this._mode === 'edit' || this._mode === 'create';
      const userType = localStorage.getItem('userType') ?? '';
      if (superadmin) {
        return editOrCreate && ['superadmin', 'client'].includes(userType);
      } else {
        return editOrCreate && userType == 'client';
      }
    },
    saveToken(token: string) {
      const expirationTime = 5 * 24 * 60 * 60 * 1000; // 2 days in milliseconds
      const currentTime = new Date().getTime();
      const tokenData = {
        token: token,
        expiration: currentTime + expirationTime,
      };
      localStorage.setItem('token', JSON.stringify(tokenData));
    },
    getToken() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error(); // 'Token not found in local storage'
        }

        const tokenData = JSON.parse(token);
        if (!tokenData || !tokenData.expiration) {
          throw new Error(); // 'Invalid token format'
        }

        const currentTime = new Date().getTime();
        if (currentTime > tokenData.expiration) {
          throw new Error(); // 'Token has expired'
        }

        return tokenData.token;
      } catch (error) {
        // Remove token from local storage
        this.removeUserInfo();
        return null;
      }
    },
    saveUserInfo(user: iUser) {
      localStorage.setItem('fullName', user.full_name ?? 'Lets Event User');
      localStorage.setItem('email', user.email ?? '');
      localStorage.setItem('userType', user.type ?? 'guest');
      localStorage.setItem('logo', user.logo_url ?? logoImage);

      if (user.type == 'merchant') {
        localStorage.setItem('businessName', user.business_name ?? '');
        localStorage.setItem('licenseType', user.license_type ?? '');
        localStorage.setItem('licenseNumber', user.license_number ?? '');
      }
    },
    removeUserInfo() {
      localStorage.removeItem('token');
      localStorage.removeItem('fullName');
      localStorage.removeItem('email');
      localStorage.removeItem('userType');
      localStorage.removeItem('logo');
      localStorage.removeItem('businessName');
      localStorage.removeItem('licenseType');
      localStorage.removeItem('licenseNumber');
    },
    clearLocalStorage() {
      const locale = localStorage.getItem('locale');

      localStorage.clear();

      if (locale) {
        localStorage.setItem('locale', locale);
      }
    },
  },
  persist: {
    debug: env.mode === 'local',
    key: env.app.webstorageNamespace + '.config.store',
    storage: window.localStorage,
    serializer: {
      deserialize: parse,
      serialize: stringify,
    },
  },
});
