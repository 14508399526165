import { reactive } from 'vue';
import { backendApi, AxiosError } from '@/http';
import { $t } from '@/i18n';
import { FilterType, HeaderType, OrderByType, ParametersType, SortItem } from '@/utils/types/dataTable';

// Define reactive data for the data table
export const dataTable = reactive({
  loading: true as boolean,
  alert: {
    text: null as string | null,
    type: 'warning' as 'info' | 'success' | 'warning' | 'error',
  },
  headers: undefined as HeaderType[] | undefined,
  options: {
    itemsPerPage: 10,
    page: 1,
    sortBy: [] as SortItem[],
  },
  filter: {} as Record<string, string | number | boolean | null>, // Tabs, types, and queries
  search: {} as Record<string, string | number | boolean | null>, // Search form
  totalItems: 0,
  data: [],
  selected: [],
  itemsPerPageOptions: [
    { title: 'PAGINATE.5', value: 5 },
    { title: 'PAGINATE.10', value: 10 },
    { title: 'PAGINATE.20', value: 20 },
    { title: 'PAGINATE.30', value: 30 },
    { title: 'PAGINATE.50', value: 50 },
    { title: 'PAGINATE.100', value: 100 },
    { title: 'PAGINATE.ALL', value: -1 },
  ],
});

// Reset the data table to its initial state
function resetDataTable() {
  dataTable.options.itemsPerPage = 10;
  dataTable.options.page = 1;
  dataTable.options.sortBy = [];

  dataTable.totalItems = 0;
  dataTable.data = [];
  dataTable.selected = [];
}

// Fetch data from the backend based on specified parameters
async function getData(endPoint: string) {
  try {
    dataTable.loading = true;
    dataTable.alert.text = null;
    dataTable.data = [];

    // Define parameters object for API request
    const params: ParametersType = {
      per_page: null as number | null,
      page: null as number | null,
      order_by: [] as OrderByType[],
      filter: [] as FilterType[],
    };

    // Remove filters where value is null or 'all'
    params['filter'] = [
      // Filter: query, tab, or type
      ...Object.entries(dataTable.filter)
        .filter(([, value]) => value && value !== 'all')
        .map(([key, value]) => ({ key, value })),
      // Search form
      ...Object.entries(dataTable.search)
        .filter(([, value]) => value !== null)
        .map(([key, value]) => ({ key, value })),
    ];

    // Order by
    params['order_by'] = dataTable.options.sortBy
      .filter((item) => item.order === 'asc' || item.order === 'desc')
      .map((item) => ({
        key: item.key,
        direction: item.order as 'asc' | 'desc',
      }));

    // Paginate
    if (dataTable.options.itemsPerPage !== -1 && dataTable.options.page) {
      params['per_page'] = dataTable.options.itemsPerPage;
      params['page'] = dataTable.options.page;
    }

    // Make the API request
    const result = await backendApi.get(endPoint, { params: params });

    // Update reactive data with the received data
    dataTable.data = result.data.data;

    if (result.data.meta) {
      dataTable.options.itemsPerPage = result.data.meta.per_page;
      dataTable.totalItems = result.data.meta.total;
    } else {
      dataTable.totalItems = dataTable.data.length;
    }
  } catch (error: unknown) {
    // Handle errors, show an alert
    let message = $t('ERROR.FAILED_TO_LOAD_DATA');
    if (error instanceof AxiosError && error.response?.data.message) {
      message = error.response.data.message;
    }
    dataTable.alert.text = message;
    dataTable.alert.type = 'error';
  } finally {
    // Set loading to false after request completion
    dataTable.loading = false;
  }
}

// Clear filter values and fetch updated data
function clearFilter(endPoint: string) {
  for (const key in dataTable.search) {
    dataTable.search[key] = null;
  }
  getData(endPoint);
}

export const dataTableActions = {
  getData: getData,
  resetDataTable: resetDataTable,
  clearFilter: clearFilter,
};
