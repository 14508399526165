import { ar } from 'vuetify/lib/locale/index.mjs';

import enStrings from '@/i18n/lang/en';

import countries from '@/i18n/lang/countries/ar';
import currencies from '@/i18n/lang/currencies/ar';
import saudiCities from '@/i18n/lang/saudi_cities/ar';

const arStrings: typeof enStrings = {
  $vuetify: { ...ar },
  ...countries,
  ...currencies,
  ...saudiCities,
  I_HAVE_ACCOUNT_LOGIN: 'لدي حساب، سجل الدخول',
  CONFIRM_REGISTRATION: 'تأكيد التسجيل',
  'CRITERIA_TYPES.dropdown-choices': 'قائمة منسدلة',
  'CRITERIA_TYPES.multiple-choices': 'خيارات متعددة',
  'CRITERIA_TYPES.questions': 'قائمة أسئلة',
  'CRITERIA_TYPES.single-choice': 'خيار واحد',
  'ERROR.AN_ERROR_OCCURED_PLEASE_TRY_AGAIN_LATER': 'حدث خطأ، يرجى المحاولة مرة أخرى في وقت لاحق!',
  'ERROR.FAILED_TO_LOAD_DATA': 'فشل في استيراد البيانات!',
  'ERROR.FILE_WAS_REMOVED_SUCCESSFULLY': 'تمت إزالة الملف بنجاح',
  'ERROR.FILE_WAS_UPLOADED_SUCCESSFULLY': 'تم رفع الملف بنجاح',
  'ERROR.NO_IMAGES_TO_BE_UPLOADED': 'لا توجد صور للرفع',
  'PAGINATE.10': '10',
  'PAGINATE.100': '100',
  'PAGINATE.20': '20',
  'PAGINATE.30': '30',
  'PAGINATE.5': '5',
  'PAGINATE.50': '50',
  'PAGINATE.ALL': 'الكل',
  ACCEPT_TERMS_AND_CONDITIONS: 'أوافق على الشروط والأحكام',
  TERMS_AND_CONDITIONS: 'الشروط والأحكام',
  'BOOKING_#': 'رقم الحجز',
  NEW_PACKAGE: 'باقة جديدة',
  DATE: 'التاريخ',
  'STATUS_STRINGS.ACCEPTED': 'مقبول',
  'STATUS_STRINGS.ACTIVE': 'نشط',
  'STATUS_STRINGS.ALL': 'الكل',
  'STATUS_STRINGS.BLOCKED': 'محظور',
  'STATUS_STRINGS.CANCELED': 'ملغي',
  'STATUS_STRINGS.DELETED': 'محذوف',
  'STATUS_STRINGS.DRAFT': 'مسودة',
  'STATUS_STRINGS.EXPIRED': 'منتهية الصلاحية',
  'STATUS_STRINGS.HIDDEN': 'مخفي',
  'STATUS_STRINGS.INACTIVE': 'غير نشط',
  'STATUS_STRINGS.INITIALIZED': 'قيد الانتظار',
  'STATUS_STRINGS.PAID': 'مدفوع',
  'STATUS_STRINGS.PENDING': 'معلق',
  'STATUS_STRINGS.REJECTED': 'مرفوض',
  'STATUS_STRINGS.REQUESTED': 'بانتظار الموافقة',
  'STATUS_STRINGS.SOLVED': 'تم الرد',
  'STATUS_STRINGS.SHOWN': 'معروض',
  'STATUS_STRINGS.UNKNOWN': 'غير محدد',
  'STATUS_STRINGS.UNPAID': 'غير مدفوع',
  ACCEPTED_PAYMENTS: 'دفعات مقبولة',
  ACCOUNT_TYPE: 'نوع الحساب',
  ADD_CHOICE: 'إضافة خيار',
  ADD_QUESTION: 'إضافة سؤال',
  CLIENT_DETAILS: 'تفاصيل العميل',
  PAYMENT_GATEWAY_RESPONSE: 'رد بوابة الدفع',
  ADDRESS: 'العنوان',
  NO_SERVICE_PACKAGES_FOUND: 'لا توجد باقات متاحة لهذه الخدمة حالياً',
  CLICK_HERE_TO_ADD_NEW_PACKAGE: 'أنقر هنا لإضافة باقة جديدة',
  ADMIN: 'مدير',
  TRANSFER: 'تحويل بنكي',
  CASH: 'نقدي',
  ADS_TITLE: 'عنوان الإعلان',
  ADVERTISEMENT_LIST: 'قائمة الإعلانات',
  ADVERTISEMENT_TYPE_IDENTIFIER: 'الرمز المختصر للنوع',
  ADVERTISEMENT_TYPE_LIST: 'قائمة أنواع الإعلانات',
  ADVERTISEMENT_TYPE_TITLE: 'نوع الإعلان',
  ADVERTISEMENT_TYPE: 'نوع الإعلان',
  ADVERTISEMENT_TYPES: 'أنواع الإعلانات',
  ADVERTISEMENTS: 'الإعلانات',
  AMOUNT: 'المبلغ',
  CREATED_SUCCESSFULLY: 'تم الإنشاء بنجاح',
  UPDATED_SUCCESSFULLY: 'تم التحديث بنجاح',
  ANSWER_TYPE: 'نوع الإجابة',
  DETAILS: 'التفاصيل',
  AUTO_APPLY: 'التطبيق التلقائي',
  AUTO: 'تلقائي',
  BACK: 'العودة',
  BIRTHDATE: 'تاريخ الميلاد',
  BOOK_BEFORE: 'الحجز قبل ...',
  BOOK_BEFORE_DAYS: 'يوم/أيام',
  BOOKING_COUNTER: 'عداد الحجز',
  BOOKING_DATE: 'تاريخ الحجز',
  BOOKING_LIST: 'قائمة الحجوزات',
  BOOKING_NUMBER: 'رقم الحجز',
  BOOKING_PRICE: 'سعر الحجز',
  BOOKINGS: 'قائمة الحجوزات',
  BUSINESS_INFORMATION: 'المعلومات التجارية',
  BUSINESS_NAME: 'الاسم التجاري',
  CANCEL: 'تراجع',
  CAPACITY: 'السعة',
  CHANGE_IMAGE: 'تغيير',
  CHOICE_LIST: 'قائمة الخيارات',
  CHOICE: 'خيار',
  CHOICES: 'قائمة الخيارات',
  CHOOSE_ACCOUNT_TYPE_DESCRIPTION: 'قم بالوصول إلى لوحة تحكم مصممة خصيصًا لتلبية احتياجاتك عن طريق تحديد نوع حسابك',
  CHOOSE_ACCOUNT_TYPE: 'اختر نوع حسابك',
  CITIES_LIST: 'قائمة المدن',
  CITIES: 'المدن',
  CITY_LIST: 'قائمة المدن',
  CLEAR_SEARCH: 'تفريغ خانات البحث',
  CLEAR: 'تفريغ النموذج',
  CLIENT_GROUP: 'العميل',
  CLIENT_MESSAGE: 'نظم وأدر مناسباتك معنا',
  CLIENT_NAME: 'اسم العميل',
  CLIENT_PHONE: 'رقم هاتف العميل',
  CLIENT: 'العميل',
  CLOSE: 'إغلاق',
  CODE: 'الرمز',
  COLOR: 'اللون',
  COMMENT_STATUS: 'حالة تعليق العميل',
  COMMENT: 'تعليق العميل',
  COMMENTED_AT: 'تم التعليق في',
  LICENSE_TYPE: 'نوع رخصة العمل',
  LICENSE_TYPE_INDEX_TITLE: 'نوع الرخصة',
  FREELANCE_LICENSE: 'رخصة عمل حر',
  COMMERCIAL_REGISTER: 'السجل التجاري',
  LICENSE_NUMBER: 'رقم الرخصة',
  CONFIRM: 'تأكيد',
  FEES: 'الرسوم',
  COUNTRIES: 'البلدان',
  COUNTRY_LIST: 'قائمة البلدان',
  COUNTRY_NAME: 'اسم البلد',
  COUNTRY: 'البلد',
  COVER_IMAGE: 'صورة الغلاف',
  CREATED_AT: 'تاريخ الإنشاء',
  PAYMENT_DETAILS: 'تفاصيل الدفع',
  BOOKING_DETAILS: 'تفاصيل الحجز',
  PAYMENT_GATEWAY_DETAILS: 'تفاصيل بوابة الدفع',
  CRITERIA_LIST: 'قائمة المعايير',
  CRITERIA_TYPE: 'نوع المعيار',
  CRITERIAS: 'قائمة المعايير',
  CURRENCY_CODE: 'رمز العملة',
  CURRENCY_NAME: 'اسم العملة',
  DASHBOARD_GROUP: 'الرئيسية',
  DASHBOARD: 'لوحة التحكم',
  DAY: 'يوم',
  DELETE_ADVERTISEMENT_TYPE: 'حذف نوع الإعلان',
  DELETE_ADVERTISEMENT: 'حذف الإعلان',
  DELETE_BOOKING: 'حذف الحجز',
  DELETE_CITY: 'حذف المدينة',
  DELETE_COUNTRY: 'حذف البلد',
  DELETE_CRITERIA: 'حذف المعيار',
  DELETE_DISCOUNT_CODE: 'حذف رمز الخصم',
  DELETE_EVENT_TYPE: 'حذف نوع الفعالية',
  DELETE_PAYMENT: 'حذف عملية الدفع',
  DELETE_SERVICE_PACKAGE: 'حذف الباقة',
  DELETE_SERVICE_TYPE: 'حذف نوع الخدمة',
  DELETE_SERVICE: 'حذف الخدمة',
  DELETE_SUPPORT_MESSAGE: 'حذف رسالة الدعم الفني',
  DELETE_USER_EVENT: 'حذف المناسبة',
  DELETE_USER: 'حذف حساب المستخدم',
  DELETE: 'تأكيد الحذف',
  DELETION_CONFIRMATION_MESSAGE: 'هل تريد بالتأكيد حذف هذا العنصر؟ لا يمكن استعادته بعد تأكيد الحذف؟',
  DESCRIPTION: 'الوصف',
  DISCOUNT_CODE_LIST: 'قائمة رموز الخصم',
  DISCOUNT_CODE_STRING: 'رمز الخصم',
  DISCOUNT_CODE_USE_COUNTER: 'مرات استخدام رمز الخصم {counter} من أصل {quantity} مرة متاحة',
  DISCOUNT_CODES: 'رموز الخصم',
  DISCOUNT_PERCENTAGE: 'نسبة الخصم',
  DISPLAY_KEY: 'مفتاح العرض',
  DISPLAY_NAME: 'اسم العرض',
  DRAFT_PAYMENTS: 'دفعات غير مكتملة',
  DROPDOWN_CHOICES: 'قائمة خيارات منسدلة',
  EDIT: 'تعديل',
  EMAIL: 'البريد الإلكتروني',
  END_DATE: 'تاريخ الانتهاء',
  EVENT_TITLE: 'عنوان الفعالية',
  EVENT_TYPE_LIST: 'أنواع الفعاليات',
  EVENT_TYPE: 'نوع الفعالية',
  EVENT_TYPES: 'أنواع الفعاليات',
  EXPIRED_PAYMENTS: 'دفعات منتهية الصلاحية',
  FILTER: 'تصفية النتائج',
  FULL_NAME: 'الاسم كاملاً',
  GO_BACK: 'العودة للصفحة السابقة',
  GO_TO_HOME: 'العودة للصفحة الرئيسية',
  HIDDEN: 'مخفي',
  HOME: 'الرئيسية',
  HOUR: 'ساعة',
  ICON: 'الأيقونة',
  ISO_CODE_THREE: 'رمز ISO 3',
  ISO_CODE_TWO: 'رمز ISO 2',
  ISO_CODE: 'رمز ISO',
  ISSUE: 'مشكلة',
  LATITUDE: 'خط العرض',
  LETS_EVENT_VERSION: 'الإصدار',
  LETS_EVENT: 'Lets Event',
  LOGIN: 'تسجيل الدخول',
  LOGO_WAS_REMOVED_SUCCESSFULLY: 'تم حذف  الشعار بنجاح',
  LOGO_WAS_UPLOADED_SUCCESSFULLY: 'تمت إضافة الشعار بنجاح',
  LONGITUDE: 'خط الطول',
  MESSAGE_TYPE: 'نوع الرسالة',
  MESSAGE: 'محتوى الرسالة',
  MONTH: 'شهر',
  MULTI_LINE: 'نص طويل',
  MULTIPLE_CHOICES: 'خيارات متعددة',
  MY_PROFILE: 'حسابي الشخصي',
  NATIONAL_ID: 'رقم الهوية الوطنية',
  NATIONALITY: 'الجنسية',
  NEW_ACCOUNT: 'حساب جديد',
  BUSINESS_LOGO: 'العلامة التجارية',
  CONTACT_INFORMATION: 'معلومات الاتصال',
  ADDRESS_INFORMATION: 'العنوان',
  ADDRESS_DESCRIPTION: 'تفاصيل إضافية',
  OWNER_NAME: 'اسم المالك',
  NEW_RECORD: 'إنشاء عنصر جديد',
  NEW: 'جديد',
  NEXT_STEP: 'التالي',
  ACCEPTED: 'مقبول',
  BOOKING_INFORMATION: 'معلومات الحجز',
  COMMENT_DETAILS: 'تفاصيل التعليق',
  FAVORITE: 'المفضلة',
  LOCATION: 'الموقع',
  PACKAGE_CAPACITY: 'السعة',
  PACKAGE_TITLE: 'عنوان الباقة',
  PAID: 'مدفوع',
  MERCHANT_DETAILS: 'تفاصيل التاجر',
  REQUESTED: 'بانتظار الموافقة',
  NO_IMAGES_TO_BE_REMOVED: 'لا توجد صور للحذف',
  NO_SERVICE_PACKAGES_FOUND_CLICK_ADD: 'ملاحظة: لن يتم عرض هذه الخدمة لعدم توفر باقات لها، يرجى إضافة باقة جديدة',
  NOT_AUTO: 'غير تلقائي',
  NUM_OF_PEOPLE: 'شخص واحد | شخصان | {n} أشخاص | {n} شخص',
  NUMBER: 'رقم',
  OTP_DESCRIPTION: 'الرجاء إدخال رمز التأكيد المرسل عبر رقم جوالك المسجل لدينا',
  OTP_TITLE: 'رمز التأكيد',
  PACKAGE_LIST: 'قائمة الباقات',
  PACKAGE_NAME: 'اسم الباقة',
  PAGE_NOT_EXIST: 'الصفحة غير موجودة',
  PAGE_NOT_FOUND: 'لا يمكن العثور على هذه الصفحة',
  PASSWORD_CONFIRMATION: 'تأكيد كلمة المرور',
  PASSWORD: 'كلمة المرور',
  PAYMENT_GATEWAY: 'بوابة الدفع',
  PAYMENT_INVOICES: 'فواتير الدفع',
  PAYMENT_LIST: 'قائمة المدفوعات',
  PAYMENT_NUMBER: 'رقم الدفع',
  PAYMENT_URL: 'رابط الدفع',
  PAYMENTS: 'قائمة المدفوعات',
  PENDING_PAYMENTS: 'دفعات معلقة',
  PENDING: 'معلق',
  PERCENTAGE: 'النسبة المئوية',
  PERSONAL_NAME: 'الاسم الشخصي',
  PHONE_CODE: 'رمز الهاتف',
  PHONE: 'رقم الجوال',
  PLEASE_ENTER_YOUR_EMAIL_AND_PASSWORD: 'الرجاء إدخال البريد الإلكتروني وكلمة المرور',
  PLEASE_ENTER_YOUR_PHONE_NUMBER: 'الرجاء إدخال رقم جوالك',
  PRICE_PER_DAY: 'السعر لليوم الواحد',
  PRICE_RANGE: 'السعر (من إلى)',
  PRICE: 'السعر',
  PRINT: 'طباعة',
  PROFILE_GROUP: 'الملف الشخصي',
  MERCHANT_GROUP: 'التاجر',
  MERCHANT_NAME: 'اسم التاجر',
  QUANTITY: 'الكمية',
  QUESTION_LIST: 'قائمة الأسئلة',
  QUESTION: 'سؤال',
  QUESTIONS: 'أسئلة',
  RATE_BAD: '1-سيء',
  RATE_EXCELLENT: '5-ممتاز',
  RATE_GOOD: '4-جيد',
  RATE_NEUTRAL: '3-متوسط',
  RATE_NOT_GOOD: '2-غير جيد',
  RATE: 'التقييم',
  RATINGS: 'تقييم واحد | تقييمان | {n} تقييمات | {n} تقييم',
  REFRESH: 'تحديث',
  REGISTER: 'تسجيل',
  REGISTRATION_FORM_DESCRIPTION: 'هذا النموذج خاص بالتجار فقط، بإمكان العملاء تسجيل الدخول من خلال إدخال رقم الجوال ورمز التأكيد دون الحاجة للتسجيل المسبق في المنصة',
  REJECTED_PAYMENTS: 'دفعات مرفوضة',
  REMOVE_SELECTED_IMAGES: 'حذف الصور المحددة',
  REMOVE: 'حذف',
  RESPONSE: 'الرد',
  SEARCH: 'بحث',
  SENDER: 'المرسل',
  SENT_AT: 'تاريخ الإرسال',
  SERVICE_IMAGE: 'صورة الخدمة',
  SERVICE_IMAGES: 'صور الخدمة',
  SERVICE_LIST: 'قائمة الخدمات',
  SERVICE_NAME: 'اسم الخدمة',
  SERVICE_PACKAGE_LIST: 'قائمة الباقات',
  SERVICE_PACKAGE: 'باقة',
  SERVICE_PACKAGES: 'قائمة الباقات',
  MERCHANT_MESSAGE: 'قدم منتجاتك وخدماتك لشريحة واسعة من العملاء معنا',
  MERCHANT_REGISTRATION_FORM: 'نموذج تسجيل التجار',
  MERCHANT: 'التاجر',
  SERVICE_TYPE_LIST: 'أنواع الخدمات',
  SERVICE_TYPE: 'نوع الخدمة',
  SERVICE_TYPES: 'أنواع الخدمات',
  SERVICE: 'الخدمة',
  SERVICES: 'قائمة الخدمات',
  SETTINGS_GROUP: 'الإعدادات',
  SHOWN: 'معروض',
  SIGN_OUT: 'تسجيل الخروج',
  SINGLE_CHOICE: 'خيار وحيد',
  SINGLE_LINE: 'نص قصير',
  START_DATE: 'تاريخ البدء',
  STATUS: 'الحالة',
  SUBMIT: 'تأكيد الحفظ',
  SUBTITLE: 'العنوان الفرعي',
  SUPERADMIN: 'مدير النظام',
  SUPPORT_MESSAGE_LIST: 'رسائل الدعم الفني',
  SUPPORT_MESSAGES_GROUP: 'رسائل الدعم',
  SUPPORT_MESSAGES: 'رسائل الدعم الفني',
  TITLE: 'العنوان',
  TRANSACTION_NUMBER: 'رقم العملية',
  TYPE_IDENTIFIER: 'الرمز المختصر',
  TYPE: 'النوع',
  UNDEFINED: 'غير محدد',
  UPDATED_AT: 'آخر تحديث',
  UPLOAD_IMAGE: 'رفع صورة',
  UPLOAD_IMAGES: 'رفع صور جديدة',
  UPLOAD: 'رفع',
  USER_CLIENTS: 'قائمة العملاء',
  USER_EVENT_LIST: 'قائمة الفعاليات',
  USER_EVENT: 'الفعالية',
  USER_EVENTS: 'قائمة الفعاليات',
  USER_LIST: 'قائمة المستخدمين',
  USER_MERCHANTS: 'التجار',
  USER_SUPERADMINS: 'مشرفو النظام',
  USER_TYPE: 'نوع المستخدم',
  USERS_GROUP: 'قائمة المستخدمين',
  USERS: 'قائمة المستخدمين',
  VIEW_SERVICE_IMAGES: 'صور الخدمة ({n})',
  WEEK: 'أسبوع',
  YOU_CAN_NOT_REMOVE_THIS_RECORD: 'لا يمكنك حذف هذا العنصر',
  'USER_TYPE.CLIENT': 'عميل',
  'USER_TYPE.MERCHANT': 'تاجر',
  'USER_TYPE.SUPERADMIN': 'مسئول النظام',
  PERSONAL_INFORMATION: 'المعلومات الشخصية',
};

export default arStrings;
