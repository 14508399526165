import enStrings from '@/i18n/lang/saudi_cities/en';

const arStrings: typeof enStrings = {
  SAUDI_CITIES: {
    AFIF: 'عفيف',
    AD_DAWADIMI: 'الدوادمي',
    SAJIR: 'ساجر',
    AL_ARTAWI: 'الأرطاوي',
    ARJA: 'عرجا',
    AL_BUJAYDIYAH: 'البجادية',
    AL_FAYDAH: 'الفيضة',
    NAFI: 'نفي',
    AL_QURAYIN: 'القراين',
    SHAQRA: 'شقراء',
    MURAT: 'مرات',
    USHAYQIR: 'اوشيقر',
    AL_QASAB: 'القصب',
    HURAYMILA: 'حريملاء',
    AL_AYNAH: 'العيينة',
    RUMAH: 'رماح',
    AL_QWAYIYAH: 'القويعية',
    AL_JILAH: 'الجلة',
    AR_RAIN: 'الرين',
    MUHAYRIQAH: 'محيرقة',
    HALBAN: 'حلبان',
    AR_RAWDAH: 'الرويضة',
    AL_KHASIRAH: 'الخاصرة',
    DIRMA: 'ضرما',
    AL_MUZAHIMIYAH: 'المزاحمية',
    JU: 'جو',
    AL_GHATTAH: 'الغطغط',
    AL_JUFAYR: 'الجفير',
    RIYADH: 'الرياض',
    AL_KHARJ: 'الخرج',
    AS_SAHNAH: 'الصحنة',
    AD_DILAM: 'الدلم',
    HAWTAT_BANI_TAMIM: 'حوطة بني تميم',
    AL_HARIQ: 'الحريق',
    LAYLA: 'ليلى',
    AS_SULAYYIL: 'السليل',
    TAMRAH: 'تمرة',
    AL_KHAMASIN: 'الخماسين',
    AN_NUAYMAH: 'النويعمة',
    JEDDAH: 'جدة',
    MAKKAH: 'مكة',
    AL_JUMUM: 'الجموم',
    SHARAI_AL_MUJAHIDIN: 'شرائع المجاهدين',
    AT_TAIF: 'الطائف',
    AL_QARI: 'القري',
    SAHIN_BANI_SAAD: 'صحن بني سعد',
    YALMULAM: 'يلملم',
    HADDAD_BANI_MALIK: 'حداد بني مالك',
    ASHIRA: 'عشيرة',
    AL_MUWAIH: 'المويه',
    THULM: 'ظلم',
    AD_DAFNAH: 'الدافنة',
    AL_KHURMA: 'الخرمة',
    TARABAH: 'تربة',
    RANYA: 'رنية',
    AL_AMALIH: 'الأملح',
    HAFAR_AL_BATIN: 'حفر الباطن',
    AR_RAQAI: 'الرقعي',
    AL_QAYSUMAH: 'القيصومة',
    MADINAT_AL_MALIK_KHALID: 'مدنية الملك خالد',
    AN_NUAYRIYAH: 'النعيرية',
    AL_KHAFJI: 'الخفجي',
    AS_SARAR: 'الصرار',
    QARYAT_AL_ULYA: 'قرية العليا',
    MU_AKHILAH: 'معقلة',
    NITA: 'نيتا',
    AL_JUBAIL: 'الجبيل',
    DAMMAM: 'الدمام',
    AL_KHOBAR: 'الخبر',
    AD_DHAHRAN: 'الظهران',
    SAIHAT: 'سيهات',
    AL_QATIF: 'القطيف',
    SAFWA: 'صفوى',
    RAS_TANURA: 'رأس تنورة',
    TARUT: 'تاروت',
    ATH_THAQABAH: 'الثقبة',
    RAHIMAH: 'رحيمة',
    ABQAIQ: 'أبقيق',
    AYN_DAR: 'عين دار',
    URAYARAH: 'عريعرة',
    AL_HOFUF: 'الهفوف',
    SILWA: 'سلوى',
    AL_AQIR: 'العقير',
    AL_AHSA: 'الإحساء',
    HARAD: 'حرض',
    KHURAIS: 'خريص',
    RAFHA: 'رفحا',
    AD_DUWAYD: 'الدويد',
    UWAYQILAH: 'عويقيلة',
    MA_ANIYAH: 'معنية',
    LUQAH: 'لوقة',
    LINAH: 'لينة',
    SHU_BAT_NASAB: 'شعبة نصاب',
    NASAB: 'نصاب',
    UMM_RAHIMAH: 'أم رحيمة',
    SAMAH: 'سماح',
    SAMUDAH: 'سامودة',
    AR_AR: 'عرعر',
    TARIF: 'طريف',
    JADIDAH: 'جديدة',
    SAKAKA: 'سكاكا',
    DUMAT_AL_JANDAL: 'دومة الجندل',
    NABHK_ABU_QASR: 'نبك أبو قصر',
    QARAH: 'قارة',
    TABARJAL: 'طبرجل',
    AL_QURAYAT: 'القريات',
    AL_ISAWIYAH: 'العيساوية',
    HADITHAH: 'حديثة',
    KAF: 'كاف',
    QARAQIR: 'قراقر',
    HADRAJ: 'حدرج',
    MA_ABIYAH: 'معابية',
    TABUK: 'تبوك',
    HAQL: 'حقل',
    HALAAT_AMMAR: 'حالة عمار',
    AT_TALA_AH: 'الطلعة',
    BADE_AH: 'بديعة',
    FAJR: 'فجر',
    AKHDAR: 'أخضر',
    AL_QULAYBAH: 'القليبة',
    UMLUJ: 'أملج',
    AL_WAJH: 'الوجه',
    DHIBA: 'ضبا',
    AL_BADA: 'البدع',
    SHAQRAH: 'شقرة',
    SHARAF: 'شرف',
    SHAWQ: 'شوق',
    TIMA: 'تيماء',
    AL_JUBAWIYAH: 'الجباوية',
    AL_ULA: 'العلا',
    KHAYBAR: 'خيبر',
    AS_SULSALAH: 'الصلصلة',
    BADR_HANIN: 'بدر حنين',
    AL_MASIJID: 'المسيجيد',
    AL_WASITAH: 'الوسيطة',
    AR_RAYAN: 'الريان',
    AL_AKHAL: 'الأكحل',
    AR_RAIS: 'الريس',
    AL_HANAKIAH: 'الحناكية',
    MAHD_ADH_DHAHAB: 'مهد الذهب',
    AS_SUWAIDIRAH: 'الصويدرة',
    AL_HISU: 'الحسو',
    AL_MADINAH: 'المدينة',
    AL_MULAYLAH: 'المليلة',
    YANBU: 'ينبع',
    AL_UYAYNAH: 'العيص',
    AYN_BIN_FAHD: 'عين بن فهيد',
    BURAYDAH: 'بريدة',
    UNAYZAH: 'عنيزة',
    AL_BADA_I: 'البدائع',
    AR_RASS: 'الرس',
    RIYADH_AL_KHABRA: 'رياض الخبراء',
    AL_BUKAYRIYAH: 'البكيرية',
    AL_MUZANNIB: 'المذنب',
    UYUN_AL_JAWA: 'عيون الجواء',
    AS_SAFRA: 'الصفراء',
    AS_SHIHIYAH: 'الشيحية',
    AS_SHAMASIYAH: 'الشماسية',
    SUBAYYIH: 'صبيح',
    UQLAT_AS_SUQUR: 'عقلة الصقور',
    AD_DILIMIYAH: 'الدليمية',
    DUKHNAH: 'دخنة',
    BUQAY_AH: 'بقعاء',
    HAIL: 'حائل',
    AL_JUBBAH: 'الجبة',
    AL_HAIT: 'الحائط',
    AS_SHAMLI: 'الشملي',
    AL_MAJMA_AH: 'المجمعة',
    AZ_ZULFI: 'الزلفي',
    AL_GHAT: 'الغاط',
    TUMAYR: 'تمير',
    HAWTAT_SUDAYR: 'حوطة سدير',
    THADIQ: 'ثادق',
    RAWDAT_SUDAYR: 'روضة سدير',
    AL_ARTAWIYAH: 'الأرطاوية',
    JILAJIL: 'جلاجل',
    AL_LAYTH: 'الليث',
    AL_QUNFUDHAH: 'القنفذة',
    BALJURASHI: 'بلجرشي',
    HAMEER: 'حمير',
    AL_BAHA: 'الباحة',
    BANI_DABYAN: 'بني ضبيان',
    AL_ATAWLAH: 'الأطاولة',
    AL_MANDAQ: 'المندق',
    ABHA: 'أبها',
    KHAMIS_MUSHAIT: 'خميس مشيط',
    ASRAN: 'عسران',
    AR_RAWNAH: 'الرونة',
    AHAD_RAFIDAH: 'أحد رفيدة',
    SARAT_UBAYDAH: 'سراة عبيدة',
    DHAHRAN_AL_JANUB: 'ظهران الجنوب',
    BISHA: 'بيشة',
    SABT_AL_ULAY: 'سبت العلايا',
    AN_NAMAS: 'النماص',
    AS_SARAH: 'السرح',
    TANOMAH: 'تنومة',
    BALSAMR: 'بلسمر',
    AL_BARIQ: 'البارق',
    JAZAN: 'جيزان',
    AT_TAWAL: 'الطوال',
    ABU_ARISH: 'أبو عريش',
    SABYA: 'صبيا',
    SAMITAH: 'سامطة',
    BISH: 'بيش',
    SUQ_AL_AHAD: 'سوق الأحد',
    DAMAD: 'ضمد',
    DARB: 'درب',
    NAJRAN: 'نجران',
    AL_ARAYSAYH: 'العريسة',
    AL_FAYSALIYAH: 'الفيصلية',
    SHARURAH: 'شرورة',
  },
};

export default arStrings;
