import { type RouteRecordRaw } from 'vue-router';

// Auth
import LoginPage from '@/views/auth/LoginPage.vue';
import OtpPage from '@/views/auth/OtpPage.vue';
import RegisterPage from '@/views/auth/RegisterPage.vue';
import TermsAndConditions from '@/views/auth/TermsAndConditions.vue';

// Profile
import ProfileForm from '@/views/profile/ProfileForm.vue';

// Users
import UserForm from '@/views/users/UserForm.vue';
import UsersPage from '@/views/users/UsersPage.vue';

// Support Messages
import SupportMessageForm from '@/views/supportMessages/SupportMessageForm.vue';
import SupportMessagesPage from '@/views/supportMessages/SupportMessagesPage.vue';

const routes: RouteRecordRaw[] = [
  // Auth -----------------------------------------------------------------
  {
    path: '/login',
    component: LoginPage,
    name: 'login',
  },
  {
    path: '/otp',
    component: OtpPage,
    name: 'otp',
  },
  {
    path: '/register',
    component: RegisterPage,
    name: 'register',
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    name: 'termsAndConditions',
  },

  // Profile -----------------------------------------------------------------
  {
    path: '/my-profile',
    name: 'profile',
    component: ProfileForm,
    meta: { mode: 'view', userTypes: ['superadmin', 'merchant', 'client'] },
  },
  {
    path: '/my-profile/edit',
    name: 'profileUpdate',
    component: ProfileForm,
    meta: { mode: 'edit', userTypes: ['superadmin', 'merchant', 'client'] },
  },

  // users -----------------------------------------------------------------
  {
    path: '/users/:type(superadmin|merchant|client)',
    name: 'users',
    meta: { userTypes: ['superadmin'] },
    redirect: (to) => ({
      name: 'users.tab',
      params: { type: to.params.type, tab: 'all' },
    }),
    children: [
      {
        path: ':tab(all|active|inactive|blocked)',
        component: UsersPage,
        name: 'users.tab',
        meta: { userTypes: ['superadmin'] },
      },
      {
        path: ':id(\\d+)',
        component: UserForm,
        name: 'user',
        meta: { mode: 'view', userTypes: ['superadmin'] },
      },
      {
        path: 'create',
        component: UserForm,
        name: 'userCreate',
        meta: { mode: 'create', userTypes: ['superadmin'] },
      },
      {
        path: ':id(\\d+)/edit',
        component: UserForm,
        name: 'userUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin'] },
      },
    ],
  },

  // support-messages -----------------------------------------------------------------
  {
    path: '/support-messages',
    name: 'supportMessages',
    meta: { userTypes: ['superadmin', 'merchant', 'client'] },
    redirect: () => ({ name: 'supportMessages.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|pending|solved)',
        component: SupportMessagesPage,
        name: 'supportMessages.tab',
        meta: { userTypes: ['superadmin', 'merchant', 'client'] },
      },
      {
        path: ':id(\\d+)',
        component: SupportMessageForm,
        name: 'supportMessage',
        meta: { mode: 'view', userTypes: ['superadmin', 'merchant', 'client'] },
      },
      {
        path: 'create',
        component: SupportMessageForm,
        name: 'supportMessageCreate',
        meta: {
          mode: 'create',
          userTypes: ['superadmin', 'merchant', 'client'],
        },
      },
      {
        path: ':id(\\d+)/edit',
        component: SupportMessageForm,
        name: 'supportMessageUpdate',
        meta: { mode: 'edit', userTypes: ['superadmin', 'merchant', 'client'] },
      },
    ],
  },
];

export default routes;
