import { $t } from '@/i18n';

export type CountryCodesInterface = {
  country: string | null;
  phoneCode: string | null;
  isoCode2: string | null;
  isoCode3: string | null;
};

export const CountryCodes: CountryCodesInterface[] = [
  {
    country: $t('COUNTRY_STRINGS.AFGHANISTAN'),
    phoneCode: '+93',
    isoCode2: 'AF',
    isoCode3: 'AFG',
  },
  {
    country: $t('COUNTRY_STRINGS.ALBANIA'),
    phoneCode: '+355',
    isoCode2: 'AL',
    isoCode3: 'ALB',
  },
  {
    country: $t('COUNTRY_STRINGS.ALGERIA'),
    phoneCode: '+213',
    isoCode2: 'DZ',
    isoCode3: 'DZA',
  },
  {
    country: $t('COUNTRY_STRINGS.AMERICAN_SAMOA'),
    phoneCode: '+1-684',
    isoCode2: 'AS',
    isoCode3: 'ASM',
  },
  {
    country: $t('COUNTRY_STRINGS.ANDORRA'),
    phoneCode: '+376',
    isoCode2: 'AD',
    isoCode3: 'AND',
  },
  {
    country: $t('COUNTRY_STRINGS.ANGOLA'),
    phoneCode: '+244',
    isoCode2: 'AO',
    isoCode3: 'AGO',
  },
  {
    country: $t('COUNTRY_STRINGS.ANGUILLA'),
    phoneCode: '+1-264',
    isoCode2: 'AI',
    isoCode3: 'AIA',
  },
  {
    country: $t('COUNTRY_STRINGS.ANTIGUA_AND_BARBUDA'),
    phoneCode: '+1-268',
    isoCode2: 'AG',
    isoCode3: 'ATG',
  },
  {
    country: $t('COUNTRY_STRINGS.ARGENTINA'),
    phoneCode: '+54',
    isoCode2: 'AR',
    isoCode3: 'ARG',
  },
  {
    country: $t('COUNTRY_STRINGS.ARMENIA'),
    phoneCode: '+374',
    isoCode2: 'AM',
    isoCode3: 'ARM',
  },
  {
    country: $t('COUNTRY_STRINGS.ARUBA'),
    phoneCode: '+297',
    isoCode2: 'AW',
    isoCode3: 'ABW',
  },
  {
    country: $t('COUNTRY_STRINGS.AUSTRALIA'),
    phoneCode: '+61',
    isoCode2: 'AU',
    isoCode3: 'AUS',
  },
  {
    country: $t('COUNTRY_STRINGS.AUSTRIA'),
    phoneCode: '+43',
    isoCode2: 'AT',
    isoCode3: 'AUT',
  },
  {
    country: $t('COUNTRY_STRINGS.AZERBAIJAN'),
    phoneCode: '+994',
    isoCode2: 'AZ',
    isoCode3: 'AZE',
  },
  {
    country: $t('COUNTRY_STRINGS.BAHAMAS'),
    phoneCode: '+1-242',
    isoCode2: 'BS',
    isoCode3: 'BHS',
  },
  {
    country: $t('COUNTRY_STRINGS.BAHRAIN'),
    phoneCode: '+973',
    isoCode2: 'BH',
    isoCode3: 'BHR',
  },
  {
    country: $t('COUNTRY_STRINGS.BANGLADESH'),
    phoneCode: '+880',
    isoCode2: 'BD',
    isoCode3: 'BGD',
  },
  {
    country: $t('COUNTRY_STRINGS.BARBADOS'),
    phoneCode: '+1-246',
    isoCode2: 'BB',
    isoCode3: 'BRB',
  },
  {
    country: $t('COUNTRY_STRINGS.BELARUS'),
    phoneCode: '+375',
    isoCode2: 'BY',
    isoCode3: 'BLR',
  },
  {
    country: $t('COUNTRY_STRINGS.BELGIUM'),
    phoneCode: '+32',
    isoCode2: 'BE',
    isoCode3: 'BEL',
  },
  {
    country: $t('COUNTRY_STRINGS.BELIZE'),
    phoneCode: '+501',
    isoCode2: 'BZ',
    isoCode3: 'BLZ',
  },
  {
    country: $t('COUNTRY_STRINGS.BENIN'),
    phoneCode: '+229',
    isoCode2: 'BJ',
    isoCode3: 'BEN',
  },
  {
    country: $t('COUNTRY_STRINGS.BERMUDA'),
    phoneCode: '+1-441',
    isoCode2: 'BM',
    isoCode3: 'BMU',
  },
  {
    country: $t('COUNTRY_STRINGS.BHUTAN'),
    phoneCode: '+975',
    isoCode2: 'BT',
    isoCode3: 'BTN',
  },
  {
    country: $t('COUNTRY_STRINGS.BOLIVIA'),
    phoneCode: '+591',
    isoCode2: 'BO',
    isoCode3: 'BOL',
  },
  {
    country: $t('COUNTRY_STRINGS.BOSNIA_AND_HERZEGOVINA'),
    phoneCode: '+387',
    isoCode2: 'BA',
    isoCode3: 'BIH',
  },
  {
    country: $t('COUNTRY_STRINGS.BOTSWANA'),
    phoneCode: '+267',
    isoCode2: 'BW',
    isoCode3: 'BWA',
  },
  {
    country: $t('COUNTRY_STRINGS.BRAZIL'),
    phoneCode: '+55',
    isoCode2: 'BR',
    isoCode3: 'BRA',
  },
  {
    country: $t('COUNTRY_STRINGS.BRITISH_INDIAN_OCEAN_TERRITORY'),
    phoneCode: '+246',
    isoCode2: 'IO',
    isoCode3: 'IOT',
  },
  {
    country: $t('COUNTRY_STRINGS.BRITISH_VIRGIN_ISLANDS'),
    phoneCode: '+1-284',
    isoCode2: 'VG',
    isoCode3: 'VGB',
  },
  {
    country: $t('COUNTRY_STRINGS.BRUNEI'),
    phoneCode: '+673',
    isoCode2: 'BN',
    isoCode3: 'BRN',
  },
  {
    country: $t('COUNTRY_STRINGS.BULGARIA'),
    phoneCode: '+359',
    isoCode2: 'BG',
    isoCode3: 'BGR',
  },
  {
    country: $t('COUNTRY_STRINGS.BURKINA_FASO'),
    phoneCode: '+226',
    isoCode2: 'BF',
    isoCode3: 'BFA',
  },
  {
    country: $t('COUNTRY_STRINGS.BURUNDI'),
    phoneCode: '+257',
    isoCode2: 'BI',
    isoCode3: 'BDI',
  },
  {
    country: $t('COUNTRY_STRINGS.CAMBODIA'),
    phoneCode: '+855',
    isoCode2: 'KH',
    isoCode3: 'KHM',
  },
  {
    country: $t('COUNTRY_STRINGS.CAMEROON'),
    phoneCode: '+237',
    isoCode2: 'CM',
    isoCode3: 'CMR',
  },
  {
    country: $t('COUNTRY_STRINGS.CANADA'),
    phoneCode: '+1',
    isoCode2: 'CA',
    isoCode3: 'CAN',
  },
  {
    country: $t('COUNTRY_STRINGS.CAPE_VERDE'),
    phoneCode: '+238',
    isoCode2: 'CV',
    isoCode3: 'CPV',
  },
  {
    country: $t('COUNTRY_STRINGS.CAYMAN_ISLANDS'),
    phoneCode: '+1-345',
    isoCode2: 'KY',
    isoCode3: 'CYM',
  },
  {
    country: $t('COUNTRY_STRINGS.CENTRAL_AFRICAN_REPUBLIC'),
    phoneCode: '+236',
    isoCode2: 'CF',
    isoCode3: 'CAF',
  },
  {
    country: $t('COUNTRY_STRINGS.CHAD'),
    phoneCode: '+235',
    isoCode2: 'TD',
    isoCode3: 'TCD',
  },
  {
    country: $t('COUNTRY_STRINGS.CHILE'),
    phoneCode: '+56',
    isoCode2: 'CL',
    isoCode3: 'CHL',
  },
  {
    country: $t('COUNTRY_STRINGS.CHINA'),
    phoneCode: '+86',
    isoCode2: 'CN',
    isoCode3: 'CHN',
  },
  {
    country: $t('COUNTRY_STRINGS.CHRISTMAS_ISLAND'),
    phoneCode: '+61',
    isoCode2: 'CX',
    isoCode3: 'CXR',
  },
  {
    country: $t('COUNTRY_STRINGS.COCOS_ISLANDS'),
    phoneCode: '+61',
    isoCode2: 'CC',
    isoCode3: 'CCK',
  },
  {
    country: $t('COUNTRY_STRINGS.COLOMBIA'),
    phoneCode: '+57',
    isoCode2: 'CO',
    isoCode3: 'COL',
  },
  {
    country: $t('COUNTRY_STRINGS.COMOROS'),
    phoneCode: '+269',
    isoCode2: 'KM',
    isoCode3: 'COM',
  },
  {
    country: $t('COUNTRY_STRINGS.COOK_ISLANDS'),
    phoneCode: '+682',
    isoCode2: 'CK',
    isoCode3: 'COK',
  },
  {
    country: $t('COUNTRY_STRINGS.COSTA_RICA'),
    phoneCode: '+506',
    isoCode2: 'CR',
    isoCode3: 'CRI',
  },
  {
    country: $t('COUNTRY_STRINGS.CROATIA'),
    phoneCode: '+385',
    isoCode2: 'HR',
    isoCode3: 'HRV',
  },
  {
    country: $t('COUNTRY_STRINGS.CUBA'),
    phoneCode: '+53',
    isoCode2: 'CU',
    isoCode3: 'CUB',
  },
  {
    country: $t('COUNTRY_STRINGS.CURACAO'),
    phoneCode: '+599',
    isoCode2: 'CW',
    isoCode3: 'CUW',
  },
  {
    country: $t('COUNTRY_STRINGS.CYPRUS'),
    phoneCode: '+357',
    isoCode2: 'CY',
    isoCode3: 'CYP',
  },
  {
    country: $t('COUNTRY_STRINGS.CZECH_REPUBLIC'),
    phoneCode: '+420',
    isoCode2: 'CZ',
    isoCode3: 'CZE',
  },
  {
    country: $t('COUNTRY_STRINGS.DEMOCRATIC_REPUBLIC_OF_THE_CONGO'),
    phoneCode: '+243',
    isoCode2: 'CD',
    isoCode3: 'COD',
  },
  {
    country: $t('COUNTRY_STRINGS.DENMARK'),
    phoneCode: '+45',
    isoCode2: 'DK',
    isoCode3: 'DNK',
  },
  {
    country: $t('COUNTRY_STRINGS.DJIBOUTI'),
    phoneCode: '+253',
    isoCode2: 'DJ',
    isoCode3: 'DJI',
  },
  {
    country: $t('COUNTRY_STRINGS.DOMINICA'),
    phoneCode: '+1-767',
    isoCode2: 'DM',
    isoCode3: 'DMA',
  },
  {
    country: $t('COUNTRY_STRINGS.DOMINICAN_REPUBLIC'),
    phoneCode: '+1-809',
    isoCode2: 'DO',
    isoCode3: 'DOM',
  },
  {
    country: $t('COUNTRY_STRINGS.EAST_TIMOR'),
    phoneCode: '+670',
    isoCode2: 'TL',
    isoCode3: 'TLS',
  },
  {
    country: $t('COUNTRY_STRINGS.ECUADOR'),
    phoneCode: '+593',
    isoCode2: 'EC',
    isoCode3: 'ECU',
  },
  {
    country: $t('COUNTRY_STRINGS.EGYPT'),
    phoneCode: '+20',
    isoCode2: 'EG',
    isoCode3: 'EGY',
  },
  {
    country: $t('COUNTRY_STRINGS.EL_SALVADOR'),
    phoneCode: '+503',
    isoCode2: 'SV',
    isoCode3: 'SLV',
  },
  {
    country: $t('COUNTRY_STRINGS.EQUATORIAL_GUINEA'),
    phoneCode: '+240',
    isoCode2: 'GQ',
    isoCode3: 'GNQ',
  },
  {
    country: $t('COUNTRY_STRINGS.ERITREA'),
    phoneCode: '+291',
    isoCode2: 'ER',
    isoCode3: 'ERI',
  },
  {
    country: $t('COUNTRY_STRINGS.ESTONIA'),
    phoneCode: '+372',
    isoCode2: 'EE',
    isoCode3: 'EST',
  },
  {
    country: $t('COUNTRY_STRINGS.ETHIOPIA'),
    phoneCode: '+251',
    isoCode2: 'ET',
    isoCode3: 'ETH',
  },
  {
    country: $t('COUNTRY_STRINGS.FALKLAND_ISLANDS'),
    phoneCode: '+500',
    isoCode2: 'FK',
    isoCode3: 'FLK',
  },
  {
    country: $t('COUNTRY_STRINGS.FAROE_ISLANDS'),
    phoneCode: '+298',
    isoCode2: 'FO',
    isoCode3: 'FRO',
  },
  {
    country: $t('COUNTRY_STRINGS.FIJI'),
    phoneCode: '+679',
    isoCode2: 'FJ',
    isoCode3: 'FJI',
  },
  {
    country: $t('COUNTRY_STRINGS.FINLAND'),
    phoneCode: '+358',
    isoCode2: 'FI',
    isoCode3: 'FIN',
  },
  {
    country: $t('COUNTRY_STRINGS.FRANCE'),
    phoneCode: '+33',
    isoCode2: 'FR',
    isoCode3: 'FRA',
  },
  {
    country: $t('COUNTRY_STRINGS.FRENCH_POLYNESIA'),
    phoneCode: '+689',
    isoCode2: 'PF',
    isoCode3: 'PYF',
  },
  {
    country: $t('COUNTRY_STRINGS.GABON'),
    phoneCode: '+241',
    isoCode2: 'GA',
    isoCode3: 'GAB',
  },
  {
    country: $t('COUNTRY_STRINGS.GAMBIA'),
    phoneCode: '+220',
    isoCode2: 'GM',
    isoCode3: 'GMB',
  },
  {
    country: $t('COUNTRY_STRINGS.GEORGIA'),
    phoneCode: '+995',
    isoCode2: 'GE',
    isoCode3: 'GEO',
  },
  {
    country: $t('COUNTRY_STRINGS.GERMANY'),
    phoneCode: '+49',
    isoCode2: 'DE',
    isoCode3: 'DEU',
  },
  {
    country: $t('COUNTRY_STRINGS.GHANA'),
    phoneCode: '+233',
    isoCode2: 'GH',
    isoCode3: 'GHA',
  },
  {
    country: $t('COUNTRY_STRINGS.GIBRALTAR'),
    phoneCode: '+350',
    isoCode2: 'GI',
    isoCode3: 'GIB',
  },
  {
    country: $t('COUNTRY_STRINGS.GREECE'),
    phoneCode: '+30',
    isoCode2: 'GR',
    isoCode3: 'GRC',
  },
  {
    country: $t('COUNTRY_STRINGS.GREENLAND'),
    phoneCode: '+299',
    isoCode2: 'GL',
    isoCode3: 'GRL',
  },
  {
    country: $t('COUNTRY_STRINGS.GRENADA'),
    phoneCode: '+1-473',
    isoCode2: 'GD',
    isoCode3: 'GRD',
  },
  {
    country: $t('COUNTRY_STRINGS.GUAM'),
    phoneCode: '+1-671',
    isoCode2: 'GU',
    isoCode3: 'GUM',
  },
  {
    country: $t('COUNTRY_STRINGS.GUATEMALA'),
    phoneCode: '+502',
    isoCode2: 'GT',
    isoCode3: 'GTM',
  },
  {
    country: $t('COUNTRY_STRINGS.GUERNSEY'),
    phoneCode: '+44-1481',
    isoCode2: 'GG',
    isoCode3: 'GGY',
  },
  {
    country: $t('COUNTRY_STRINGS.GUINEA'),
    phoneCode: '+224',
    isoCode2: 'GN',
    isoCode3: 'GIN',
  },
  {
    country: $t('COUNTRY_STRINGS.GUINEA_BISSAU'),
    phoneCode: '+245',
    isoCode2: 'GW',
    isoCode3: 'GNB',
  },
  {
    country: $t('COUNTRY_STRINGS.GUYANA'),
    phoneCode: '+592',
    isoCode2: 'GY',
    isoCode3: 'GUY',
  },
  {
    country: $t('COUNTRY_STRINGS.HAITI'),
    phoneCode: '+509',
    isoCode2: 'HT',
    isoCode3: 'HTI',
  },
  {
    country: $t('COUNTRY_STRINGS.HONDURAS'),
    phoneCode: '+504',
    isoCode2: 'HN',
    isoCode3: 'HND',
  },
  {
    country: $t('COUNTRY_STRINGS.HONG_KONG'),
    phoneCode: '+852',
    isoCode2: 'HK',
    isoCode3: 'HKG',
  },
  {
    country: $t('COUNTRY_STRINGS.HUNGARY'),
    phoneCode: '+36',
    isoCode2: 'HU',
    isoCode3: 'HUN',
  },
  {
    country: $t('COUNTRY_STRINGS.ICELAND'),
    phoneCode: '+354',
    isoCode2: 'IS',
    isoCode3: 'ISL',
  },
  {
    country: $t('COUNTRY_STRINGS.INDIA'),
    phoneCode: '+91',
    isoCode2: 'IN',
    isoCode3: 'IND',
  },
  {
    country: $t('COUNTRY_STRINGS.INDONESIA'),
    phoneCode: '+62',
    isoCode2: 'ID',
    isoCode3: 'IDN',
  },
  {
    country: $t('COUNTRY_STRINGS.IRAN'),
    phoneCode: '+98',
    isoCode2: 'IR',
    isoCode3: 'IRN',
  },
  {
    country: $t('COUNTRY_STRINGS.IRAQ'),
    phoneCode: '+964',
    isoCode2: 'IQ',
    isoCode3: 'IRQ',
  },
  {
    country: $t('COUNTRY_STRINGS.IRELAND'),
    phoneCode: '+353',
    isoCode2: 'IE',
    isoCode3: 'IRL',
  },
  {
    country: $t('COUNTRY_STRINGS.ISLE_OF_MAN'),
    phoneCode: '+44-1624',
    isoCode2: 'IM',
    isoCode3: 'IMN',
  },
  {
    country: $t('COUNTRY_STRINGS.ITALY'),
    phoneCode: '+39',
    isoCode2: 'IT',
    isoCode3: 'ITA',
  },
  {
    country: $t('COUNTRY_STRINGS.IVORY_COAST'),
    phoneCode: '+225',
    isoCode2: 'CI',
    isoCode3: 'CIV',
  },
  {
    country: $t('COUNTRY_STRINGS.JAMAICA'),
    phoneCode: '+1-876',
    isoCode2: 'JM',
    isoCode3: 'JAM',
  },
  {
    country: $t('COUNTRY_STRINGS.JAPAN'),
    phoneCode: '+81',
    isoCode2: 'JP',
    isoCode3: 'JPN',
  },
  {
    country: $t('COUNTRY_STRINGS.JERSEY'),
    phoneCode: '+44-1534',
    isoCode2: 'JE',
    isoCode3: 'JEY',
  },
  {
    country: $t('COUNTRY_STRINGS.JORDAN'),
    phoneCode: '+962',
    isoCode2: 'JO',
    isoCode3: 'JOR',
  },
  {
    country: $t('COUNTRY_STRINGS.KAZAKHSTAN'),
    phoneCode: '+7',
    isoCode2: 'KZ',
    isoCode3: 'KAZ',
  },
  {
    country: $t('COUNTRY_STRINGS.KENYA'),
    phoneCode: '+254',
    isoCode2: 'KE',
    isoCode3: 'KEN',
  },
  {
    country: $t('COUNTRY_STRINGS.KIRIBATI'),
    phoneCode: '+686',
    isoCode2: 'KI',
    isoCode3: 'KIR',
  },
  {
    country: $t('COUNTRY_STRINGS.KOSOVO'),
    phoneCode: '+383',
    isoCode2: 'XK',
    isoCode3: 'XKX',
  },
  {
    country: $t('COUNTRY_STRINGS.KUWAIT'),
    phoneCode: '+965',
    isoCode2: 'KW',
    isoCode3: 'KWT',
  },
  {
    country: $t('COUNTRY_STRINGS.KYRGYZSTAN'),
    phoneCode: '+996',
    isoCode2: 'KG',
    isoCode3: 'KGZ',
  },
  {
    country: $t('COUNTRY_STRINGS.LAOS'),
    phoneCode: '+856',
    isoCode2: 'LA',
    isoCode3: 'LAO',
  },
  {
    country: $t('COUNTRY_STRINGS.LATVIA'),
    phoneCode: '+371',
    isoCode2: 'LV',
    isoCode3: 'LVA',
  },
  {
    country: $t('COUNTRY_STRINGS.LEBANON'),
    phoneCode: '+961',
    isoCode2: 'LB',
    isoCode3: 'LBN',
  },
  {
    country: $t('COUNTRY_STRINGS.LESOTHO'),
    phoneCode: '+266',
    isoCode2: 'LS',
    isoCode3: 'LSO',
  },
  {
    country: $t('COUNTRY_STRINGS.LIBERIA'),
    phoneCode: '+231',
    isoCode2: 'LR',
    isoCode3: 'LBR',
  },
  {
    country: $t('COUNTRY_STRINGS.LIBYA'),
    phoneCode: '+218',
    isoCode2: 'LY',
    isoCode3: 'LBY',
  },
  {
    country: $t('COUNTRY_STRINGS.LIECHTENSTEIN'),
    phoneCode: '+423',
    isoCode2: 'LI',
    isoCode3: 'LIE',
  },
  {
    country: $t('COUNTRY_STRINGS.LITHUANIA'),
    phoneCode: '+370',
    isoCode2: 'LT',
    isoCode3: 'LTU',
  },
  {
    country: $t('COUNTRY_STRINGS.LUXEMBOURG'),
    phoneCode: '+352',
    isoCode2: 'LU',
    isoCode3: 'LUX',
  },
  {
    country: $t('COUNTRY_STRINGS.MACAU'),
    phoneCode: '+853',
    isoCode2: 'MO',
    isoCode3: 'MAC',
  },
  {
    country: $t('COUNTRY_STRINGS.MACEDONIA'),
    phoneCode: '+389',
    isoCode2: 'MK',
    isoCode3: 'MKD',
  },
  {
    country: $t('COUNTRY_STRINGS.MADAGASCAR'),
    phoneCode: '+261',
    isoCode2: 'MG',
    isoCode3: 'MDG',
  },
  {
    country: $t('COUNTRY_STRINGS.MALAWI'),
    phoneCode: '+265',
    isoCode2: 'MW',
    isoCode3: 'MWI',
  },
  {
    country: $t('COUNTRY_STRINGS.MALAYSIA'),
    phoneCode: '+60',
    isoCode2: 'MY',
    isoCode3: 'MYS',
  },
  {
    country: $t('COUNTRY_STRINGS.MALDIVES'),
    phoneCode: '+960',
    isoCode2: 'MV',
    isoCode3: 'MDV',
  },
  {
    country: $t('COUNTRY_STRINGS.MALI'),
    phoneCode: '+223',
    isoCode2: 'ML',
    isoCode3: 'MLI',
  },
  {
    country: $t('COUNTRY_STRINGS.MALTA'),
    phoneCode: '+356',
    isoCode2: 'MT',
    isoCode3: 'MLT',
  },
  {
    country: $t('COUNTRY_STRINGS.MARSHALL_ISLANDS'),
    phoneCode: '+692',
    isoCode2: 'MH',
    isoCode3: 'MHL',
  },
  {
    country: $t('COUNTRY_STRINGS.MAURITANIA'),
    phoneCode: '+222',
    isoCode2: 'MR',
    isoCode3: 'MRT',
  },
  {
    country: $t('COUNTRY_STRINGS.MAURITIUS'),
    phoneCode: '+230',
    isoCode2: 'MU',
    isoCode3: 'MUS',
  },
  {
    country: $t('COUNTRY_STRINGS.MAYOTTE'),
    phoneCode: '+262',
    isoCode2: 'YT',
    isoCode3: 'MYT',
  },
  {
    country: $t('COUNTRY_STRINGS.MEXICO'),
    phoneCode: '+52',
    isoCode2: 'MX',
    isoCode3: 'MEX',
  },
  {
    country: $t('COUNTRY_STRINGS.MICRONESIA'),
    phoneCode: '+691',
    isoCode2: 'FM',
    isoCode3: 'FSM',
  },
  {
    country: $t('COUNTRY_STRINGS.MOLDOVA'),
    phoneCode: '+373',
    isoCode2: 'MD',
    isoCode3: 'MDA',
  },
  {
    country: $t('COUNTRY_STRINGS.MONACO'),
    phoneCode: '+377',
    isoCode2: 'MC',
    isoCode3: 'MCO',
  },
  {
    country: $t('COUNTRY_STRINGS.MONGOLIA'),
    phoneCode: '+976',
    isoCode2: 'MN',
    isoCode3: 'MNG',
  },
  {
    country: $t('COUNTRY_STRINGS.MONTENEGRO'),
    phoneCode: '+382',
    isoCode2: 'ME',
    isoCode3: 'MNE',
  },
  {
    country: $t('COUNTRY_STRINGS.MONTSERRAT'),
    phoneCode: '+1-664',
    isoCode2: 'MS',
    isoCode3: 'MSR',
  },
  {
    country: $t('COUNTRY_STRINGS.MOROCCO'),
    phoneCode: '+212',
    isoCode2: 'MA',
    isoCode3: 'MAR',
  },
  {
    country: $t('COUNTRY_STRINGS.MOZAMBIQUE'),
    phoneCode: '+258',
    isoCode2: 'MZ',
    isoCode3: 'MOZ',
  },
  {
    country: $t('COUNTRY_STRINGS.MYANMAR'),
    phoneCode: '+95',
    isoCode2: 'MM',
    isoCode3: 'MMR',
  },
  {
    country: $t('COUNTRY_STRINGS.NAMIBIA'),
    phoneCode: '+264',
    isoCode2: 'NA',
    isoCode3: 'NAM',
  },
  {
    country: $t('COUNTRY_STRINGS.NAURU'),
    phoneCode: '+674',
    isoCode2: 'NR',
    isoCode3: 'NRU',
  },
  {
    country: $t('COUNTRY_STRINGS.NEPAL'),
    phoneCode: '+977',
    isoCode2: 'NP',
    isoCode3: 'NPL',
  },
  {
    country: $t('COUNTRY_STRINGS.NETHERLANDS'),
    phoneCode: '+31',
    isoCode2: 'NL',
    isoCode3: 'NLD',
  },
  {
    country: $t('COUNTRY_STRINGS.NETHERLANDS_ANTILLES'),
    phoneCode: '+599',
    isoCode2: 'AN',
    isoCode3: 'ANT',
  },
  {
    country: $t('COUNTRY_STRINGS.NEW_CALEDONIA'),
    phoneCode: '+687',
    isoCode2: 'NC',
    isoCode3: 'NCL',
  },
  {
    country: $t('COUNTRY_STRINGS.NEW_ZEALAND'),
    phoneCode: '+64',
    isoCode2: 'NZ',
    isoCode3: 'NZL',
  },
  {
    country: $t('COUNTRY_STRINGS.NICARAGUA'),
    phoneCode: '+505',
    isoCode2: 'NI',
    isoCode3: 'NIC',
  },
  {
    country: $t('COUNTRY_STRINGS.NIGER'),
    phoneCode: '+227',
    isoCode2: 'NE',
    isoCode3: 'NER',
  },
  {
    country: $t('COUNTRY_STRINGS.NIGERIA'),
    phoneCode: '+234',
    isoCode2: 'NG',
    isoCode3: 'NGA',
  },
  {
    country: $t('COUNTRY_STRINGS.NIUE'),
    phoneCode: '+683',
    isoCode2: 'NU',
    isoCode3: 'NIU',
  },
  {
    country: $t('COUNTRY_STRINGS.NORTH_KOREA'),
    phoneCode: '+850',
    isoCode2: 'KP',
    isoCode3: 'PRK',
  },
  {
    country: $t('COUNTRY_STRINGS.NORTHERN_MARIANA_ISLANDS'),
    phoneCode: '+1-670',
    isoCode2: 'MP',
    isoCode3: 'MNP',
  },
  {
    country: $t('COUNTRY_STRINGS.NORWAY'),
    phoneCode: '+47',
    isoCode2: 'NO',
    isoCode3: 'NOR',
  },
  {
    country: $t('COUNTRY_STRINGS.OMAN'),
    phoneCode: '+968',
    isoCode2: 'OM',
    isoCode3: 'OMN',
  },
  {
    country: $t('COUNTRY_STRINGS.PAKISTAN'),
    phoneCode: '+92',
    isoCode2: 'PK',
    isoCode3: 'PAK',
  },
  {
    country: $t('COUNTRY_STRINGS.PALAU'),
    phoneCode: '+680',
    isoCode2: 'PW',
    isoCode3: 'PLW',
  },
  {
    country: $t('COUNTRY_STRINGS.PALESTINE'),
    phoneCode: '+970',
    isoCode2: 'PS',
    isoCode3: 'PSE',
  },
  {
    country: $t('COUNTRY_STRINGS.PANAMA'),
    phoneCode: '+507',
    isoCode2: 'PA',
    isoCode3: 'PAN',
  },
  {
    country: $t('COUNTRY_STRINGS.PAPUA_NEW_GUINEA'),
    phoneCode: '+675',
    isoCode2: 'PG',
    isoCode3: 'PNG',
  },
  {
    country: $t('COUNTRY_STRINGS.PARAGUAY'),
    phoneCode: '+595',
    isoCode2: 'PY',
    isoCode3: 'PRY',
  },
  {
    country: $t('COUNTRY_STRINGS.PERU'),
    phoneCode: '+51',
    isoCode2: 'PE',
    isoCode3: 'PER',
  },
  {
    country: $t('COUNTRY_STRINGS.PHILIPPINES'),
    phoneCode: '+63',
    isoCode2: 'PH',
    isoCode3: 'PHL',
  },
  {
    country: $t('COUNTRY_STRINGS.PITCAIRN'),
    phoneCode: '+64',
    isoCode2: 'PN',
    isoCode3: 'PCN',
  },
  {
    country: $t('COUNTRY_STRINGS.POLAND'),
    phoneCode: '+48',
    isoCode2: 'PL',
    isoCode3: 'POL',
  },
  {
    country: $t('COUNTRY_STRINGS.PORTUGAL'),
    phoneCode: '+351',
    isoCode2: 'PT',
    isoCode3: 'PRT',
  },
  {
    country: $t('COUNTRY_STRINGS.PUERTO_RICO'),
    phoneCode: '+1-787',
    isoCode2: 'PR',
    isoCode3: 'PRI',
  },
  {
    country: $t('COUNTRY_STRINGS.QATAR'),
    phoneCode: '+974',
    isoCode2: 'QA',
    isoCode3: 'QAT',
  },
  {
    country: $t('COUNTRY_STRINGS.REPUBLIC_OF_THE_CONGO'),
    phoneCode: '+242',
    isoCode2: 'CG',
    isoCode3: 'COG',
  },
  {
    country: $t('COUNTRY_STRINGS.REUNION'),
    phoneCode: '+262',
    isoCode2: 'RE',
    isoCode3: 'REU',
  },
  {
    country: $t('COUNTRY_STRINGS.ROMANIA'),
    phoneCode: '+40',
    isoCode2: 'RO',
    isoCode3: 'ROU',
  },
  {
    country: $t('COUNTRY_STRINGS.RUSSIA'),
    phoneCode: '+7',
    isoCode2: 'RU',
    isoCode3: 'RUS',
  },
  {
    country: $t('COUNTRY_STRINGS.RWANDA'),
    phoneCode: '+250',
    isoCode2: 'RW',
    isoCode3: 'RWA',
  },
  {
    country: $t('COUNTRY_STRINGS.SAINT_BARTHELEMY'),
    phoneCode: '+590',
    isoCode2: 'BL',
    isoCode3: 'BLM',
  },
  {
    country: $t('COUNTRY_STRINGS.SAINT_HELENA'),
    phoneCode: '+290',
    isoCode2: 'SH',
    isoCode3: 'SHN',
  },
  {
    country: $t('COUNTRY_STRINGS.SAINT_KITTS_AND_NEVIS'),
    phoneCode: '+1-869',
    isoCode2: 'KN',
    isoCode3: 'KNA',
  },
  {
    country: $t('COUNTRY_STRINGS.SAINT_LUCIA'),
    phoneCode: '+1-758',
    isoCode2: 'LC',
    isoCode3: 'LCA',
  },
  {
    country: $t('COUNTRY_STRINGS.SAINT_MARTIN'),
    phoneCode: '+590',
    isoCode2: 'MF',
    isoCode3: 'MAF',
  },
  {
    country: $t('COUNTRY_STRINGS.SAINT_PIERRE_AND_MIQUELON'),
    phoneCode: '+508',
    isoCode2: 'PM',
    isoCode3: 'SPM',
  },
  {
    country: $t('COUNTRY_STRINGS.SAINT_VINCENT_AND_THE_GRENADINES'),
    phoneCode: '+1-784',
    isoCode2: 'VC',
    isoCode3: 'VCT',
  },
  {
    country: $t('COUNTRY_STRINGS.SAMOA'),
    phoneCode: '+685',
    isoCode2: 'WS',
    isoCode3: 'WSM',
  },
  {
    country: $t('COUNTRY_STRINGS.SAN_MARINO'),
    phoneCode: '+378',
    isoCode2: 'SM',
    isoCode3: 'SMR',
  },
  {
    country: $t('COUNTRY_STRINGS.SAO_TOME_AND_PRINCIPE'),
    phoneCode: '+239',
    isoCode2: 'ST',
    isoCode3: 'STP',
  },
  {
    country: $t('COUNTRY_STRINGS.SAUDI_ARABIA'),
    phoneCode: '+966',
    isoCode2: 'SA',
    isoCode3: 'SAU',
  },
  {
    country: $t('COUNTRY_STRINGS.SENEGAL'),
    phoneCode: '+221',
    isoCode2: 'SN',
    isoCode3: 'SEN',
  },
  {
    country: $t('COUNTRY_STRINGS.SERBIA'),
    phoneCode: '+381',
    isoCode2: 'RS',
    isoCode3: 'SRB',
  },
  {
    country: $t('COUNTRY_STRINGS.SEYCHELLES'),
    phoneCode: '+248',
    isoCode2: 'SC',
    isoCode3: 'SYC',
  },
  {
    country: $t('COUNTRY_STRINGS.SIERRA_LEONE'),
    phoneCode: '+232',
    isoCode2: 'SL',
    isoCode3: 'SLE',
  },
  {
    country: $t('COUNTRY_STRINGS.SINGAPORE'),
    phoneCode: '+65',
    isoCode2: 'SG',
    isoCode3: 'SGP',
  },
  {
    country: $t('COUNTRY_STRINGS.SINT_MAARTEN'),
    phoneCode: '+1-721',
    isoCode2: 'SX',
    isoCode3: 'SXM',
  },
  {
    country: $t('COUNTRY_STRINGS.SLOVAKIA'),
    phoneCode: '+421',
    isoCode2: 'SK',
    isoCode3: 'SVK',
  },
  {
    country: $t('COUNTRY_STRINGS.SLOVENIA'),
    phoneCode: '+386',
    isoCode2: 'SI',
    isoCode3: 'SVN',
  },
  {
    country: $t('COUNTRY_STRINGS.SOLOMON_ISLANDS'),
    phoneCode: '+677',
    isoCode2: 'SB',
    isoCode3: 'SLB',
  },
  {
    country: $t('COUNTRY_STRINGS.SOMALIA'),
    phoneCode: '+252',
    isoCode2: 'SO',
    isoCode3: 'SOM',
  },
  {
    country: $t('COUNTRY_STRINGS.SOUTH_AFRICA'),
    phoneCode: '+27',
    isoCode2: 'ZA',
    isoCode3: 'ZAF',
  },
  {
    country: $t('COUNTRY_STRINGS.SOUTH_KOREA'),
    phoneCode: '+82',
    isoCode2: 'KR',
    isoCode3: 'KOR',
  },
  {
    country: $t('COUNTRY_STRINGS.SOUTH_SUDAN'),
    phoneCode: '+211',
    isoCode2: 'SS',
    isoCode3: 'SSD',
  },
  {
    country: $t('COUNTRY_STRINGS.SPAIN'),
    phoneCode: '+34',
    isoCode2: 'ES',
    isoCode3: 'ESP',
  },
  {
    country: $t('COUNTRY_STRINGS.SRI_LANKA'),
    phoneCode: '+94',
    isoCode2: 'LK',
    isoCode3: 'LKA',
  },
  {
    country: $t('COUNTRY_STRINGS.SUDAN'),
    phoneCode: '+249',
    isoCode2: 'SD',
    isoCode3: 'SDN',
  },
  {
    country: $t('COUNTRY_STRINGS.SURINAME'),
    phoneCode: '+597',
    isoCode2: 'SR',
    isoCode3: 'SUR',
  },
  {
    country: $t('COUNTRY_STRINGS.SVALBARD_AND_JAN_MAYEN'),
    phoneCode: '+47',
    isoCode2: 'SJ',
    isoCode3: 'SJM',
  },
  {
    country: $t('COUNTRY_STRINGS.SWAZILAND'),
    phoneCode: '+268',
    isoCode2: 'SZ',
    isoCode3: 'SWZ',
  },
  {
    country: $t('COUNTRY_STRINGS.SWEDEN'),
    phoneCode: '+46',
    isoCode2: 'SE',
    isoCode3: 'SWE',
  },
  {
    country: $t('COUNTRY_STRINGS.SWITZERLAND'),
    phoneCode: '+41',
    isoCode2: 'CH',
    isoCode3: 'CHE',
  },
  {
    country: $t('COUNTRY_STRINGS.SYRIA'),
    phoneCode: '+963',
    isoCode2: 'SY',
    isoCode3: 'SYR',
  },
  {
    country: $t('COUNTRY_STRINGS.TAIWAN'),
    phoneCode: '+886',
    isoCode2: 'TW',
    isoCode3: 'TWN',
  },
  {
    country: $t('COUNTRY_STRINGS.TAJIKISTAN'),
    phoneCode: '+992',
    isoCode2: 'TJ',
    isoCode3: 'TJK',
  },
  {
    country: $t('COUNTRY_STRINGS.TANZANIA'),
    phoneCode: '+255',
    isoCode2: 'TZ',
    isoCode3: 'TZA',
  },
  {
    country: $t('COUNTRY_STRINGS.THAILAND'),
    phoneCode: '+66',
    isoCode2: 'TH',
    isoCode3: 'THA',
  },
  {
    country: $t('COUNTRY_STRINGS.TOGO'),
    phoneCode: '+228',
    isoCode2: 'TG',
    isoCode3: 'TGO',
  },
  {
    country: $t('COUNTRY_STRINGS.TOKELAU'),
    phoneCode: '+690',
    isoCode2: 'TK',
    isoCode3: 'TKL',
  },
  {
    country: $t('COUNTRY_STRINGS.TONGA'),
    phoneCode: '+676',
    isoCode2: 'TO',
    isoCode3: 'TON',
  },
  {
    country: $t('COUNTRY_STRINGS.TRINIDAD_AND_TOBAGO'),
    phoneCode: '+1-868',
    isoCode2: 'TT',
    isoCode3: 'TTO',
  },
  {
    country: $t('COUNTRY_STRINGS.TUNISIA'),
    phoneCode: '+216',
    isoCode2: 'TN',
    isoCode3: 'TUN',
  },
  {
    country: $t('COUNTRY_STRINGS.TURKEY'),
    phoneCode: '+90',
    isoCode2: 'TR',
    isoCode3: 'TUR',
  },
  {
    country: $t('COUNTRY_STRINGS.TURKMENISTAN'),
    phoneCode: '+993',
    isoCode2: 'TM',
    isoCode3: 'TKM',
  },
  {
    country: $t('COUNTRY_STRINGS.TURKS_AND_CAICOS_ISLANDS'),
    phoneCode: '+1-649',
    isoCode2: 'TC',
    isoCode3: 'TCA',
  },
  {
    country: $t('COUNTRY_STRINGS.TUVALU'),
    phoneCode: '+688',
    isoCode2: 'TV',
    isoCode3: 'TUV',
  },
  {
    country: $t('COUNTRY_STRINGS.US_VIRGIN_ISLANDS'),
    phoneCode: '+1-340',
    isoCode2: 'VI',
    isoCode3: 'VIR',
  },
  {
    country: $t('COUNTRY_STRINGS.UGANDA'),
    phoneCode: '+256',
    isoCode2: 'UG',
    isoCode3: 'UGA',
  },
  {
    country: $t('COUNTRY_STRINGS.UKRAINE'),
    phoneCode: '+380',
    isoCode2: 'UA',
    isoCode3: 'UKR',
  },
  {
    country: $t('COUNTRY_STRINGS.UNITED_ARAB_EMIRATES'),
    phoneCode: '+971',
    isoCode2: 'AE',
    isoCode3: 'ARE',
  },
  {
    country: $t('COUNTRY_STRINGS.UNITED_KINGDOM'),
    phoneCode: '+44',
    isoCode2: 'GB',
    isoCode3: 'GBR',
  },
  {
    country: $t('COUNTRY_STRINGS.UNITED_STATES'),
    phoneCode: '+1',
    isoCode2: 'US',
    isoCode3: 'USA',
  },
  {
    country: $t('COUNTRY_STRINGS.URUGUAY'),
    phoneCode: '+598',
    isoCode2: 'UY',
    isoCode3: 'URY',
  },
  {
    country: $t('COUNTRY_STRINGS.UZBEKISTAN'),
    phoneCode: '+998',
    isoCode2: 'UZ',
    isoCode3: 'UZB',
  },
  {
    country: $t('COUNTRY_STRINGS.VANUATU'),
    phoneCode: '+678',
    isoCode2: 'VU',
    isoCode3: 'VUT',
  },
  {
    country: $t('COUNTRY_STRINGS.VATICAN'),
    phoneCode: '+379',
    isoCode2: 'VA',
    isoCode3: 'VAT',
  },
  {
    country: $t('COUNTRY_STRINGS.VENEZUELA'),
    phoneCode: '+58',
    isoCode2: 'VE',
    isoCode3: 'VEN',
  },
  {
    country: $t('COUNTRY_STRINGS.VIETNAM'),
    phoneCode: '+84',
    isoCode2: 'VN',
    isoCode3: 'VNM',
  },
  {
    country: $t('COUNTRY_STRINGS.WALLIS_AND_FUTUNA'),
    phoneCode: '+681',
    isoCode2: 'WF',
    isoCode3: 'WLF',
  },
  {
    country: $t('COUNTRY_STRINGS.WESTERN_SAHARA'),
    phoneCode: '+212',
    isoCode2: 'EH',
    isoCode3: 'ESH',
  },
  {
    country: $t('COUNTRY_STRINGS.YEMEN'),
    phoneCode: '+967',
    isoCode2: 'YE',
    isoCode3: 'YEM',
  },
  {
    country: $t('COUNTRY_STRINGS.ZAMBIA'),
    phoneCode: '+260',
    isoCode2: 'ZM',
    isoCode3: 'ZMB',
  },
  {
    country: $t('COUNTRY_STRINGS.ZIMBABWE'),
    phoneCode: '+263',
    isoCode2: 'ZW',
    isoCode3: 'ZWE',
  },
];
