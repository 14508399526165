import { type RouteRecordRaw } from 'vue-router';

import auth_routes from '@/router/routes/auth_routes';
import setting_routes from '@/router/routes/setting_routes';
import system_routes from '@/router/routes/system_routes';
import merchant_routes from '@/router/routes/merchant_routes';
import client_routes from '@/router/routes/client_routes';

// const uuidRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

export const routes: RouteRecordRaw[] = [
  ...auth_routes,
  ...setting_routes,
  ...system_routes,
  ...merchant_routes,
  ...client_routes,
];
