export default {
  SAUDI_CITIES: {
    AFIF: 'Afif',
    AD_DAWADIMI: 'Ad Dawadimi',
    SAJIR: 'Sajir',
    AL_ARTAWI: 'Al-Artawi',
    ARJA: 'Arja',
    AL_BUJAYDIYAH: 'Al-Bujaydiyah',
    AL_FAYDAH: 'Al-Faydah',
    NAFI: 'Nafi',
    AL_QURAYIN: 'Al-Qurayin',
    SHAQRA: 'Shaqra',
    MURAT: 'Murat',
    USHAYQIR: 'Ushayqir',
    AL_QASAB: 'Al-Qasab',
    HURAYMILA: 'Huraymila',
    AL_AYNAH: "Al-'Aynah",
    RUMAH: 'Rumah',
    AL_QWAYIYAH: "Al-Qway'iyah",
    AL_JILAH: 'Al-Jilah',
    AR_RAIN: 'Ar-Rain',
    MUHAYRIQAH: 'Muhayriqah',
    HALBAN: 'Halban',
    AR_RAWDAH: 'Ar Rawdah',
    AL_KHASIRAH: 'Al-Khasirah',
    DIRMA: 'Dirma',
    AL_MUZAHIMIYAH: 'Al-Muzahimiyah',
    JU: 'Ju',
    AL_GHATTAH: 'Al-Ghattah',
    AL_JUFAYR: 'Al-Jufayr',
    RIYADH: 'Riyadh',
    AL_KHARJ: 'Al-Kharj',
    AS_SAHNAH: 'As Sahnah',
    AD_DILAM: 'Ad Dilam',
    HAWTAT_BANI_TAMIM: 'Hawtat Bani Tamim',
    AL_HARIQ: 'Al-Hariq',
    LAYLA: 'Layla',
    AS_SULAYYIL: 'As Sulayyil',
    TAMRAH: 'Tamrah',
    AL_KHAMASIN: 'Al-Khamasin',
    AN_NUAYMAH: "An Nu'aymah",
    JEDDAH: 'Jeddah',
    MAKKAH: 'Makkah',
    AL_JUMUM: 'Al-Jumum',
    SHARAI_AL_MUJAHIDIN: "Shara'i Al-Mujahidin",
    AT_TAIF: 'At Taif',
    AL_QARI: 'Al-Qari',
    SAHIN_BANI_SAAD: 'Sahin Bani Saad',
    YALMULAM: 'Yalmulam',
    HADDAD_BANI_MALIK: 'Haddad Bani Malik',
    ASHIRA: 'Ashira',
    AL_MUWAIH: 'Al-Muwaih',
    THULM: 'Thulm',
    AD_DAFNAH: 'Ad Dafnah',
    AL_KHURMA: 'Al-Khurma',
    TARABAH: 'Tarabah',
    RANYA: 'Ranya',
    AL_AMALIH: 'Al-Amalih',
    HAFAR_AL_BATIN: 'Hafar Al-Batin',
    AR_RAQAI: "Ar-Raqa'i",
    AL_QAYSUMAH: 'Al-Qaysumah',
    MADINAT_AL_MALIK_KHALID: 'Madinat Al-Malik Khalid',
    AN_NUAYRIYAH: "An Nu'ayriyah",
    AL_KHAFJI: 'Al Khafji',
    AS_SARAR: 'As-Sarar',
    QARYAT_AL_ULYA: 'Qaryat Al-Ulya',
    MU_AKHILAH: "Mu'akhilah",
    NITA: 'Nita',
    AL_JUBAIL: 'Al-Jubail',
    DAMMAM: 'Dammam',
    AL_KHOBAR: 'Al-Khobar',
    AD_DHAHRAN: 'Ad Dhahran',
    SAIHAT: 'Saihat',
    AL_QATIF: 'Al-Qatif',
    SAFWA: 'Safwa',
    RAS_TANURA: 'Ras Tanura',
    TARUT: 'Tarut',
    ATH_THAQABAH: 'Ath Thaqabah',
    RAHIMAH: 'Rahimah',
    ABQAIQ: 'Abqaiq',
    AYN_DAR: 'Ayn Dar',
    URAYARAH: 'Urayarah',
    AL_HOFUF: 'Al-Hofuf',
    SILWA: 'Silwa',
    AL_AQIR: 'Al-Aqir',
    AL_AHSA: 'Al-Ahsa',
    HARAD: 'Harad',
    KHURAIS: 'Khurais',
    RAFHA: 'Rafha',
    AD_DUWAYD: 'Ad Duwayd',
    UWAYQILAH: 'Uwayqilah',
    MA_ANIYAH: "Ma'aniyah",
    LUQAH: 'Luqah',
    LINAH: 'Linah',
    SHU_BAT_NASAB: "Shu'bat Nasab",
    NASAB: 'Nasab',
    UMM_RAHIMAH: 'Umm Rahimah',
    SAMAH: 'Samah',
    SAMUDAH: 'Samudah',
    AR_AR: "Ar'ar",
    TARIF: 'Tarif',
    JADIDAH: 'Jadidah',
    SAKAKA: 'Sakaka',
    DUMAT_AL_JANDAL: 'Dumat Al-Jandal',
    NABHK_ABU_QASR: 'Nabhk Abu Qasr',
    QARAH: 'Qarah',
    TABARJAL: 'Tabarjal',
    AL_QURAYAT: 'Al-Qurayat',
    AL_ISAWIYAH: "Al-'Isawiyah",
    HADITHAH: 'Hadithah',
    KAF: 'Kaf',
    QARAQIR: 'Qaraqir',
    HADRAJ: 'Hadraj',
    MA_ABIYAH: "Ma'abiyah",
    TABUK: 'Tabuk',
    HAQL: 'Haql',
    HALAAT_AMMAR: "Halaat 'Ammar",
    AT_TALA_AH: "At Tala'ah",
    BADE_AH: "Bade'ah",
    FAJR: 'Fajr',
    AKHDAR: 'Akhdar',
    AL_QULAYBAH: 'Al-Qulaybah',
    UMLUJ: 'Umluj',
    AL_WAJH: 'Al-Wajh',
    DHIBA: 'Dhiba',
    AL_BADA: "Al-Bada'",
    SHAQRAH: 'Shaqrah',
    SHARAF: 'Sharaf',
    SHAWQ: 'Shawq',
    TIMA: "Tima'",
    AL_JUBAWIYAH: 'Al-Jubawiyah',
    AL_ULA: 'Al-Ula',
    KHAYBAR: 'Khaybar',
    AS_SULSALAH: 'As-Sulsalah',
    BADR_HANIN: 'Badr Hanin',
    AL_MASIJID: 'Al-Masijid',
    AL_WASITAH: 'Al-Wasitah',
    AR_RAYAN: 'Ar-Rayan',
    AL_AKHAL: 'Al-Akhal',
    AR_RAIS: 'Ar-Rais',
    AL_HANAKIAH: 'Al-Hanakiah',
    MAHD_ADH_DHAHAB: 'Mahd Adh Dhahab',
    AS_SUWAIDIRAH: 'As-Suwaidirah',
    AL_HISU: 'Al-Hisu',
    AL_MADINAH: 'Al-Madinah',
    AL_MULAYLAH: 'Al-Mulaylah',
    YANBU: 'Yanbu',
    AL_UYAYNAH: "Al-'Uyaynah",
    AYN_BIN_FAHD: 'Ayn Bin Fahd',
    BURAYDAH: 'Buraydah',
    UNAYZAH: 'Unayzah',
    AL_BADA_I: "Al-Bada'i",
    AR_RASS: 'Ar-Rass',
    RIYADH_AL_KHABRA: 'Riyadh Al-Khabra',
    AL_BUKAYRIYAH: 'Al-Bukayriyah',
    AL_MUZANNIB: 'Al-Muzannib',
    UYUN_AL_JAWA: "Uyun Al-Jawa'",
    AS_SAFRA: "As-Safra'",
    AS_SHIHIYAH: 'Ash-Shihiyah',
    AS_SHAMASIYAH: 'Ash-Shamasiyah',
    SUBAYYIH: 'Subayyih',
    UQLAT_AS_SUQUR: 'Uqlat As-Suqur',
    AD_DILIMIYAH: 'Ad-Dilimiyah',
    DUKHNAH: 'Dukhnah',
    BUQAY_AH: "Buqay'ah",
    HAIL: 'Hail',
    AL_JUBBAH: 'Al-Jubbah',
    AL_HAIT: 'Al-Hait',
    AS_SHAMLI: 'Ash-Shamli',
    AL_MAJMA_AH: "Al-Majma'ah",
    AZ_ZULFI: 'Az-Zulfi',
    AL_GHAT: 'Al-Ghat',
    TUMAYR: 'Tumayr',
    HAWTAT_SUDAYR: 'Hawtat Sudayr',
    THADIQ: 'Thadiq',
    RAWDAT_SUDAYR: 'Rawdat Sudayr',
    AL_ARTAWIYAH: 'Al-Artawiyah',
    JILAJIL: 'Jilajil',
    AL_LAYTH: 'Al-Layth',
    AL_QUNFUDHAH: 'Al-Qunfudhah',
    BALJURASHI: 'Baljurashi',
    HAMEER: 'Hameer',
    AL_BAHA: 'Al-Baha',
    BANI_DABYAN: 'Bani Dabyan',
    AL_ATAWLAH: 'Al-Atawlah',
    AL_MANDAQ: 'Al-Mandaq',
    ABHA: 'Abha',
    KHAMIS_MUSHAIT: 'Khamis Mushait',
    ASRAN: 'Asran',
    AR_RAWNAH: 'Ar-Rawnah',
    AHAD_RAFIDAH: 'Ahad Rafidah',
    SARAT_UBAYDAH: "Sarat 'Ubaydah",
    DHAHRAN_AL_JANUB: 'Dhahran Al-Janub',
    BISHA: 'Bisha',
    SABT_AL_ULAY: "Sabt Al-'Ulayya",
    AN_NAMAS: 'An-Namas',
    AS_SARAH: 'As-Sarah',
    TANOMAH: 'Tanomah',
    BALSAMR: 'Balsamr',
    AL_BARIQ: 'Al-Bariq',
    JAZAN: 'Jazan',
    AT_TAWAL: 'At-Tawal',
    ABU_ARISH: 'Abu Arish',
    SABYA: 'Sabya',
    SAMITAH: 'Samitah',
    BISH: 'Bish',
    SUQ_AL_AHAD: 'Suq Al-Ahad',
    DAMAD: 'Damad',
    DARB: 'Darb',
    NAJRAN: 'Najran',
    AL_ARAYSAYH: "Al-'Araysah",
    AL_FAYSALIYAH: 'Al-Faysaliyah',
    SHARURAH: 'Sharurah',
  },
};
