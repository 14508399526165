import { createRouter, createWebHistory, type Router } from 'vue-router';
import { routes } from '@/router/routes';
import { useConfigStore } from '@/stores';

const router: Router = createRouter({
  history: createWebHistory(),
  scrollBehavior: (to, _, savedPosition) => {
    if (to.hash) {
      return {
        el: to.hash,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // stores
  const configStore = useConfigStore();

  const meta = to.meta;
  const userTypes = meta && meta.userTypes;
  const mode = meta && meta.mode;

  const token = configStore.getToken();
  const userType = localStorage.getItem('userType');

  // No meta data or userTypes key, allow navigation
  if (!meta || !userTypes) {
    next();
    return;
  }

  // update the mode state
  if (mode && ['view', 'create', 'edit'].includes(mode as string)) {
    configStore.setMode(mode as 'view' | 'create' | 'edit');
  } else {
    configStore.setMode(undefined);
  }

  // No token or userType, route to login
  if (!token || !userType) {
    configStore.clearLocalStorage();
    router.push({ name: 'login' });
    return;
  }

  // check the user permission
  if ((userTypes as string[]).includes(userType as string)) {
    next(); // User type is allowed, allow navigation
  } else {
    router.push({ name: '404' }); // User type is not allowed, route to 404
  }
});

export default router;
