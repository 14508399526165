import { type RouteRecordRaw } from 'vue-router';

// Bookings
import BookingForm from '@/views/bookings/BookingForm.vue';
import BookingsPage from '@/views/bookings/BookingsPage.vue';

// Payments
import PaymentForm from '@/views/payments/PaymentForm.vue';
import PaymentsPage from '@/views/payments/PaymentsPage.vue';

// User Events
import UserEventForm from '@/views/userEvents/UserEventForm.vue';
import UserEventsPage from '@/views/userEvents/UserEventsPage.vue';

const routes: RouteRecordRaw[] = [
  // bookings -----------------------------------------------------------------
  {
    path: '/bookings',
    name: 'bookings',
    meta: { userTypes: ['superadmin', 'merchant', 'client'] },
    redirect: () => ({ name: 'bookings.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|requested|accepted|rejected|paid|canceled)',
        component: BookingsPage,
        name: 'bookings.tab',
        meta: { userTypes: ['superadmin', 'merchant', 'client'] },
      },
      {
        path: ':id(\\d+)',
        component: BookingForm,
        name: 'booking',
        meta: { mode: 'view', userTypes: ['superadmin', 'merchant', 'client'] },
      },
      {
        path: 'create',
        component: BookingForm,
        name: 'bookingCreate',
        meta: { mode: 'create', userTypes: ['client'] },
      },
      {
        path: ':id(\\d+)/edit',
        component: BookingForm,
        name: 'bookingUpdate',
        meta: { mode: 'edit', userTypes: ['merchant', 'client'] },
      },
    ],
  },

  // payments -----------------------------------------------------------------
  {
    path: '/payments',
    name: 'payments',
    meta: { userTypes: ['superadmin', 'client', 'merchant'] },
    redirect: () => ({ name: 'payments.tab', params: { tab: 'all' } }),
    children: [
      {
        path: ':tab(all|pending|accepted|rejected|expired)',
        component: PaymentsPage,
        name: 'payments.tab',
        meta: { userTypes: ['superadmin', 'client', 'merchant'] },
      },
      {
        path: ':id(\\d+)',
        component: PaymentForm,
        name: 'payment',
        meta: { mode: 'view', userTypes: ['superadmin', 'client', 'merchant'] },
      },
      {
        path: 'create',
        component: PaymentForm,
        name: 'paymentCreate',
        meta: { mode: 'create', userTypes: ['merchant', 'client'] },
      },
      {
        path: ':id(\\d+)/edit',
        component: PaymentForm,
        name: 'paymentUpdate',
        meta: { mode: 'edit', userTypes: [] },
      },
    ],
  },

  // user-events -----------------------------------------------------------------
  {
    path: '/user-events',
    meta: { userTypes: ['superadmin', 'client'] },
    redirect: () => ({ name: 'userEvents' }),
    children: [
      {
        path: '',
        component: UserEventsPage,
        name: 'userEvents',
        meta: { userTypes: ['superadmin', 'client'] },
      },
      {
        path: ':id(\\d+)',
        component: UserEventForm,
        name: 'userEvent',
        meta: { mode: 'view', userTypes: ['superadmin', 'client'] },
      },
      {
        path: 'create',
        component: UserEventForm,
        name: 'userEventCreate',
        meta: { mode: 'create', userTypes: ['client'] },
      },
      {
        path: ':id(\\d+)/edit',
        component: UserEventForm,
        name: 'userEventUpdate',
        meta: { mode: 'edit', userTypes: ['client'] },
      },
    ],
  },
];

export default routes;
