import { type RouteRecordRaw } from 'vue-router';

// Dashboard
import Dashboard from '@/views/dashboard/DashboardPage.vue';

// Errors
import Error404Page from '@/views/errors/Error404Page.vue';

const routes: RouteRecordRaw[] = [
  // Dashboard -----------------------------------------------------------------
  {
    path: '/',
    component: Dashboard,
    name: 'dashboard',
    meta: { userTypes: ['superadmin', 'merchant', 'client'] },
  },

  // 404 -----------------------------------------------------------------
  {
    path: '/404',
    component: Error404Page,
    name: '404',
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error404Page,
  },
];

export default routes;
